@import "../../variable";

.toolbarWrapper{
    display: flex;
    justify-content: space-between;

    .lefttool{
        display: flex;
        gap: 20px;

        .thirdcontentselect{
            font-size: 15px;
            font-family: "Kanit";
            padding: 0 10px;
            font-weight: bold;
        }
    }
    .righttool{
        display: flex;
        flex-wrap: nowrap;
        // align-items: flex-end;
        align-items: center;
        

        .searchtext{
            font-family: "Kanit";
            box-sizing: border-box;
            border: 1px solid gray;
            padding: 5px 10px;
            font-size: 15px;
            &:focus{
                outline: none;
            }
        }
        .searchbtn{
            display: flex;
            border: 1px solid $themecolor1;
            justify-content: center;
            align-items: center;
            padding: 2.7px;
            background-color: $themecolor1;
            cursor: pointer;

            .searchimg{

            }
        }


        



    }
}


.arrowParentWrapper{
    position:relative;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    margin-left: 5px;

    // background-color: lawngreen;
}


.btnnobackground{
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: transparent;
    border: none;
    cursor: pointer;
    .btnnobackgroundimg{
        width: 30px;
        height: 25px;
    }
}


.columnCheckboxWrapper{
    // display:none; 
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    // justify-content: space-evenly;
    gap: 3px;
    font-family: $defaultfont;

    position: absolute;
    left: -50px;
    top: 30px;
    padding: 10px 5px;
    z-index: 1;
    // opacity: 0;

    width: 150px;
    background-color: #FEF5E5;
}


.hiddenColumnToggle:checked + div {
    display: none;
}