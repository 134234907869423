.imageWrapper{
    display: flex;
    gap:10px;
    align-items: stretch;
  }
  .imageColumnWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .imgBox{
      // cursor: pointer;
    width: 205px;
    height: 120px;
    border: 0.25px solid #000000;
    padding: 0 10px;
    border-radius: 5px;
    background-color: #F7F7F7;
  }
  .imageBoxMini{
    @extend .imgBox;
    padding: 0 ;

    width: 50px;
    height: 40px;
  }
  .image{
    width: 100%;
    height: 100%;
  }