.customCsvUploadButton {

    // Hide the input element
    input[type="file"] {
        display: none;
    }

    // border: 1px solid #ccc;
    background-color: #ddd;
    border-radius: 10px;
    padding: 6px 12px;
    cursor: pointer;

    &:hover {
        background-color: #aaa;
    }
}