@import "../../variable";

.indexBlock{
    

    .firstIndexBox{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .frontGround{
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
        }
        .firstIndexImg{
            width: 100%;
             @media screen and (max-width: 426px) {
                object-position: center;
                object-fit: none;
             }
        }
        .firstIndexContent{
            position: absolute;
            text-align: center;

            .firstIndexText{
                color: white;
                font-size: 40px;
                margin: auto auto 20px auto;
                @media screen and (max-width: 426px) {
                    font-size: 20px;
                 }
            }
        }
    }
    .secondIndexBox{
        background-color: $themecolor2;
        padding: 30px 0;

        .secondIndexTextGroup{
            @extend .wrappertextcontent;
            .secondIndexTextTopic{
                margin: 0 0 10px 0;
                font-size: 40px;
                @media screen and (max-width: 426px) {
                    font-size: 30px;
                 }
            }
        }

        .secondIndexImgLine{
            display: flex;
            gap: 10px;

            @media screen and (max-width: 426px) {
                flex-direction: column-reverse;
                align-items: center;
                margin: 20px 0 0 0;
             }
            .leftSideImgContainer{
                flex-basis: 50%;
                @media screen and (max-width: 426px) {
                    margin: 0 30px 0 0 ;
                 }
                .leftSideImg{
                    margin: 50px auto auto auto;
                    width: 100%;

                    @media screen and (max-width: 426px) {
                        margin: 0;
                     }
                }
            }
            .rightSideImgContainer{
                flex-basis: 50%;
                @media screen and (max-width: 426px) {
                    margin: 0 0 0 30px ;
                 }
                .rightSideImg{
                    width: 100%;
                }
            }
        }
    }
    .thirdIndexBox{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 50px;
        gap: 50px;
        @media screen and (max-width: 426px) {
            padding: 20px;
            text-align: center;
         }
        .thirdIndexContent{
            display: flex;
            gap: 50px;
            @media screen and (max-width: 426px) {
                flex-direction: column;
             }
            .thirdIndexCard{
                text-align: center;

                .thirdIndexCardImg{
                    width: 100px;
                }
                .thirdIndexCardHeader{
                    text-align: left;
                    font-weight: bold;
                    margin: 10px 0;

                }
                .thirdIndexCardBody{
                    text-align: left;

                }
            }
        }
    }
    .fourthIndexBox{
        background-color: $themecolor4;
        display: flex;
        gap: 20px;
        padding: 30px 90px;
        align-items: center;
        @media screen and (max-width: 426px) {
            text-align: center;
            flex-direction: column;
            padding: 30px 20px;
         }
         .fourthIndexLeftSideContent{
            text-align: center;
        }
        .fourthIndexRightSideContent{
            display: flex;
            flex-direction: column;
            @media screen and (max-width: 426px) {
                gap: 20px;
                text-align: left;
             }
            .fourthIndexRightSideItems{
                display: flex;
                gap: 20px;
                align-items: center;

                .fourthIndexIndex{
                    padding: 16px 20px;
                    border-radius: 100%;
                    background-color: $themecolor2;
                    font-weight: bold;

                }
            }
        }
    }
    .fifthIndexBox{

        .fifthIndexHeader{
            background-color: $themecolor2;
            text-align: center;
            padding: 20px 0;
            @media screen and (max-width: 426px) {
                padding: 20px;
              }
        }
        .fifthIndexContent{
            display: flex;
            @media screen and (max-width: 426px) {
                flex-direction: column-reverse;
              } 
            .fifthIndexContentImg{
                width: 400px;
                @media screen and (max-width: 426px) {
                    width: 100%;
                  }
            }
            .fifthIndexContentRightSide{
                position: relative;
                padding: 20px;

                .fifthIndexContentRightSideFooter{
                    position: absolute;
                    text-align: right;
                    bottom: 30px;
                    right: 20px;
                    @media screen and (max-width: 426px) {
                        position: static;
                        text-align: left;
                      }
                      .fifthIndexContentRightSideFooterBtn{
                          @extend .inputBtn;
                          @media screen and (max-width: 426px) {
                            display: none;
                          }
                      }
                }
            }
        }
    }
}

.inputBtn{
    cursor: pointer;
    padding: 10px 20px;
    border: none;
    background-color: #EF8834;
    font-size: 20px;
}

.wrappertextcontent{
    padding: 0 90px;
    @media screen and (max-width: 426px) {
        padding: 0 20px;
     }
}