@import "../../../variable.scss";

.reportWrapper{
    display: flex;
    position: relative;
    top: -20px;
    left: -25px;
    gap: 10px;
    height: 50px;
    // background-color: lawngreen;

    &::before{
        position: absolute;
        left: 0px;
        top: 0px;
        content: '';
        background-color: white;
        height: 50px;
        width: 900px;
        z-index: 0;
    }

    .selectionButton {
        position: relative;
        border: none;
        // background-color: transparent;
        cursor: pointer;
        font-family: $defaultfont;
        font-size: 25px;
        font-weight: 500;
        // padding: 0px 20px;
        // width: 150px;
        padding: 0 20px;
        color: gray;
        // background-color: violet;
        background-color: #A6A6A6;
        border-top-right-radius: 5px;
        border-top-left-radius: 5px;

        &.selected{
            background-color: $themecolor4;
            color: black;

            
        }
    }
}