@import "../../variable";

.navTopBar {
  position: sticky;
  z-index: 10;
  top: 0px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  height: 60px;
  background-color: $themecolor2;

  padding: 5px 200px;

  @media screen and (max-width: 769px) {
    padding: 5px 40px;
  }
  @media screen and (max-width: 426px) {
    padding: 5px 20px;
  }

  .logoButton {
    display: flex;
    height: 60px;
    width: 60px;
    padding: 0px;
    align-items: center;
    font-family: "Kanit";
    gap: 20px;
    border: none;
    background-color: transparent;
    cursor: pointer;
    .logoImage{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .VerifyContainer{
    display: flex;
    gap: 10px;

    .waitVerify{
      color: #FFE38F ;
    }
    .nonVerify{
      color: #FFFF;
    }
    .Verify{
      color: #219653 ;
    }
  }
}

.menues {
  display: flex;
  flex-direction: row;
  gap: 20px;
  // position: relative;

  align-items: center;

  .changeStatusBtn{
    border: none;
    font-size: 16px;
    font-family: "Kanit";
    background-color: 	hsl(46, 100%, 50%);
    cursor: pointer;
    border-radius: 5px;
    padding: 10px 6px;

    &:hover {
      background-color: 	hsl(46, 100%, 65%);
    }
  }

  .isNotLogInBtn{
    @extend .changeStatusBtn;
    background-color: 	transparent;
    &:hover {
      background-color: 	hsl(30, 100%, 65%);
    }
  }

  .menu {
    display: flex;
    align-items: center;
    gap: 10px;
    position: relative;

    .icon {
      height: 25px;
      width: 25px;
    }

    .notifyNumber{
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: -15px;
      top: -10px;
      font-size: 14px;
      background-color: red;
      color: white;
      width: 21px;
      border-radius: 100%;
      cursor: pointer;
    }

    .dropDownContainer{
      
      position: relative;

      .dropDownContent{
        display: flex;
        flex-direction: column;

        position: absolute;
        top: 44px;
        right: 0px;
        transform: translateX(50%);
        border:1px solid hsl(30, 100%, 65%);

        width: 170px;
        // width: 30px;

        .dropDownItems{
          font-family: $defaultfont;
          font-size: 16px;
          border: none;
          // padding: 10px 25px;
          padding: 10px 0px;
          cursor: pointer;
          font-family: "Kanit";
          // color: white;
          // color:hsl(30, 100%, 65%);
          color: black;
          
          background-color: hsl(60, 100%, 97%);
          
          &:hover{
            background-color: hsl(60, 90%, 77%);
          }
        }
        .logOutBtn{
          @extend .dropDownItems;
          // background-color: red;
        }
      }
    }

    .dropDownNotification{
      position: absolute;
      top: 47px;
      
    }
  }
}

.notMobile{

  @media screen and (max-width: 426px) {
    display: none;
  }
}

.burgerimg{
  cursor: pointer;

  @media screen and (min-width: 426px) {
    display: none;
  }
}

.mobileNAv{
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100vw;
  height: 100vh;
  background-color: black;
  padding: 30px;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  .btnLine{

  }
  .mobileNavItems{
    color: white;
    font-size: 30px;

    hr{
      margin: 5px 0;
      border-color: white;
      border-top-width: 3px;
      border-right-width: 0;
      border-bottom-width: 0;
      border-left-width: 0;
      height: 0;
    }
  }
}