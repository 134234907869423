@import "../../variable";


  



.selectclass{
  font-size: 20px;
  .opclass{
    
  }
}

.pageWrapper {
  margin-bottom: 50px
}

.successModalWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center; 
  gap: 5px;
  padding: 50px;

  .saveText{
    margin-top: 10px;
    font-size: 30px;
    color: #74B335;

  }
}