@import './variable';

* {
  box-sizing: border-box;
}
@font-face {
  font-family: KanitRegular;
  src: url('font/Kanit-Regular.ttf');
  font-display: swap;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: KanitRegular;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // background-color:  $themecolor1;
  // background-color: #FFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h2,
h3,
h4,
h5,
p {
  margin: 0;
  font-family: KanitRegular !important;
}

a {
  text-decoration: none;
}

.App {
  display: flex;
  flex-direction: column;
  // gap: 20px;
}

.dp-flex {
  display: flex;
}

.justify-cont-ct {
  justify-content: center;
}

.wrapper {
  padding: 5px 60px;

  @media screen and (max-width: 769px) {
    padding: 5px 40px;
  }
  @media screen and (max-width: 426px) {
    padding: 5px 20px;
  }
}

// Try to fix width?
.wrapper2 {
  width: 900px;
  margin: 0px auto;
  @media screen and (max-width: 769px) {
    width: 769px;
  }
  @media screen and (max-width: 426px) {
    width: 100%;
  }
  @media screen and (max-width: 376px) {
    width: 100%;
  }
  @media screen and (max-width: 321px) {
    width: 100%;
  }
}

.hiddenInMobile {
  @media screen and (max-width: 426px) {
    display: none;
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.flexWrap {
  flex-wrap: wrap;
}

.column {
  display: flex;
  flex-direction: column;
}

.justifyContentBetween {
  justify-content: space-between;
}

.justifyContentCenter {
  justify-content: center;
}

.alignItemCenter {
  align-items: center;
}

.btnGroupFooter {
  @include flexXYAdjust(center, center);
  @include flexAxisGap(row, 10px);

  margin: 5px 0 20px 0;
  padding: 5px 60px;

  @media screen and (max-width: 769px) {
    padding: 5px 40px;
  }
  @media screen and (max-width: 426px) {
    padding: 5px 20px;
  }
}

.btnPrimary {
  box-shadow: $boxShadow1;
  background-color: $themecolor3;
  font-size: 18px;
  font-weight: 500;
  font-family: $defaultfont !important;

  padding: 5px 10px;
  border: none;
  border-radius: 3px;

  display: flex;
  flex-basis: 50%;
  justify-content: center;

  max-width: 400px;

  cursor: pointer;
}

.btnDefault {
  @extend .btnPrimary;
  background-color: $themecolor2;

  &:hover {
    background-color: $themecolor2a50;
  }
}

.btnDefault2 {
  @extend .btnPrimary;
  background-color: $themecolor5;

  &:hover {
    background-color: $themecolor5a50;
  }
}

.btnDefault3 {
  @extend .btnPrimary;
  background-color: $lightergraycolor;

  &:hover {
    background-color: $themecolor4;
  }
}

.btnDefault4 {
  @extend .btnPrimary;
  background-color: #4de805;

  &:hover {
    background-color: #3aa805;
  }
}

.btExport {
  display: flex;
  align-items: center;
  // align-items: center;
  justify-content: center;
  border: 1px solid red;
  font-size: 1rem;
  width: 140px;
  font-family: 'Kanit';
  height: 40px;
  background-color: #f2994a;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  cursor: pointer;
}

.f-basis50 {
  flex-basis: 50%;
}
.f-split {
  flex: 1 0 0;
}

.menuOnPage {
  background-color: $themecolor2;
  display: flex;
  align-items: center;
  margin-top: 10px;
  height: 50px;

  .menuOnPageItems {
    font-family: $defaultfont;
    border: none;
    background-color: transparent;
    font-size: 20px;
    font-weight: 400;
    color: #555555;
    cursor: pointer;
    width: 150px;
    // display: flex;
    // flex-wrap: nowrap;

    &:hover {
      color: black;
    }

    &.menuOnPageItemsSelected {
      color: black;
    }
  }
}
.f-gap50px {
  gap: 50px;
}

.errorMessage {
  color: red;
}

.orangeBtn {
  background-color: $themecolor2;
  border: none;
  width: 100%;
  padding: 5px 0;
  cursor: pointer;
  font-family: 'Kanit';
}

.arrowPic {
  width: 20px;
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

// CSS for loader
.loaderWrapper {
  height: 80px;
}
.loader,
.loader:before,
.loader:after {
  background: black;
  -webkit-animation: load1 1s infinite ease-in-out;
  animation: load1 1s infinite ease-in-out;
  width: 1em;
  height: 4em;
}
.loader {
  color: black;
  text-indent: -9999em;
  margin: 22px auto;
  position: relative;
  font-size: 11px;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
.loader:before,
.loader:after {
  position: absolute;
  top: 0;
  content: '';
}
.loader:before {
  left: -1.5em;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.loader:after {
  left: 1.5em;
}
@-webkit-keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
@keyframes load1 {
  0%,
  80%,
  100% {
    box-shadow: 0 0;
    height: 4em;
  }
  40% {
    box-shadow: 0 -2em;
    height: 5em;
  }
}
// END CSS for loader

// START CSS for spinner loader
.lds-ring {
  position: fixed;
  bottom: 20px;
  left: 20px;
  width: 80px;
  height: 80px;
  z-index: 1000000;
}
.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #f2994a;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #f2994a transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
// END CSS for spinner loader

//Input Styles Border Bottom
.inputLineBottom {
  font-family: 'Kanit';
  border: 0;
  border-bottom: 1px solid #b6b6b6;
  background-color: #fafafa;
  font-weight: 400;
  font-size: 15px;
  padding: 0.5em;
  flex-basis: 80%;
  width: 100%;
  ::placeholder {
    font-family: 'Kanit';
  }

  &[aria-checked='true'] {
    border-bottom: 1px solid red;
  }
}

.inputLineBottomErr {
  @extend .inputLineBottom;
  border: 1px solid red;
}

.inputBorder {
  @extend .inputLineBottom;
  border: 1px solid #b6b6b6;
  border-radius: 4px;
}

.contentWrapper {
  width: clamp(0px, calc(100% - 20px - 100px), 1215px);
  margin: 0 auto;
}

.wrapperButton {
  border: none;
  padding: 0.5em 1em;
  font-size: 14px;
  color: white;
  border-radius: 8px;
  background-color: #ff8206;
  cursor: pointer;
}

.wrapperButtonOutlined {
  @extend .wrapperButton;
  background-color: white;
  color: #ff8206;
  border: 1px solid #ff8206;
}

.wrapperButtonSmall {
  @extend .wrapperButton;
  font-size: 12px;
}

.selectInput {
  padding: 0.3em;
  font-family: KanitRegular;
  border: 0;
  font-size: 16px;
  border-radius: 4px;
  width: 100%;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
}

.errorEquation {
  border: 1px solid red;

  &:focus {
    border: 2px solid red;
  }
  &:focus-visible {
    border: 2px solid red;
    outline: none;
  }
}
