@import "../../variable";

.pagestatus{
    // position: relative;
    display: flex;
    flex-direction: column;
    background-color: $themecolor1;
    align-items: center;
    justify-content: center;
    padding: 20px 0px;
  
    .pagestatusline{
      position: absolute;
      width: 100%;
      margin: 0;
      z-index: 0;
    }
  
    .pagestatusgroupbtn{
      display: flex;
      gap: 60px;
  
      .pagestatusbtn{
        background-color: #FFF;
        border: 0.5px solid #000000;
        width: 60px;
        height: 60px;
        min-width: 0;
        font-size: 30px;
        // border: 0px;
        border-radius: 100%;
        z-index: 1;
  
        // &:hover{
        //   cursor: pointer;
        // }
        
        // Leave this for onMousePress action
        // &:focus{
        //   color: white;
        //   background-color: #535353;
        // }

        &.highlighted {
            // color: white;
            color: black;
            background-color: $themecolor2;
            border: none;
        }
      }
    }
  
  }