@import "../../../variable";

.workTypeWrapper {
    margin-top: 20px;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;

    .addWorkTypeButton{
        font-family: $defaultfont;
        background: $themecolor2;
        border-radius: 60px;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: none;
        height: 40px;
        width: 180px;
    }

    
}

.workTypeCardWrapper {
  margin-top: 20px;
  margin-bottom: 40px;
  flex-direction: column;
  align-items: center;
  flex-grow: 0;
  
  gap: 20px;

  
  // border: 1px solid red;

  .workTypeCard{
    border: 3px solid $themecolor2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 20px;
    padding:25px 30px;
    // flex-grow: 0;
    // flex: 0 0 0;
    // flex-basis: 900px;
    // width: 900px;
    width: 100%;

    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;

    .Xbtn{
      border: none;
      background-color: transparent;
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
      .Xbtnimg{
          width: 15px;
      }
    }

    .workTypeLine{
      display: flex;
      gap: 20px;
      align-items: flex-end;

      .inputBox{
        width: 100%;
        height: 30px;
        font-family: "Kanit";
      }
    }

    //Step 2
    //* RadioWrapper
    .inputLongBox{
      width: 690px;
      height: 30px;
    }
    .radioWrapper{
      display: flex;
      gap:20px;
    }
    .textAreaTopic{

    }
    .rowWrapper{
      display: flex;
      gap:30px;
      .leftSide{
        width: 450px;
        .textAreaBox{
          height: 100%;
        }
      }
      .rightSide{
        .imageWrapper{
          display: flex;
          gap:10px;
        }
        .imageColumnWrapper{
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
        .imgBox{
          width: 205;
          height: 115;
          border: 0.25px solid #000000;
          padding: 0 10px;
          border-radius: 5px;
          background-color: #F7F7F7;
        }
        .imageBoxMini{
          @extend .imgBox;
          padding: 0 ;

          width: 50px;
          height: 40px;
        }
        .image{
          width: 100%;
          height: 100%;
        }
        .imageDescription {
          font-size: 12px;
          margin-left: 12px;
        }
      }
    }
  
    .patSelectLine{

      .patSelectItem{
        font-size: 15px;
        background-color: $themecolor2a50;
        width: 250px;

        .opclass{
          background-color: $themecolor2;
        }
      }

      .patSelectedShow{
        gap: 5px;
        padding: 5px;
        width: fit-content;
        background-color: $themecolor2;

        .patShowbox{

        }

        .patSelectBtn{
          border: none;
          background-color: transparent;
          cursor: pointer;
          
          .patSelectBtnimg{
            width: 10px;
          }
        }
      }
    }
  }

}


.modaltextarea{
    width: 90%;
    font-family: "Kanit";
    background-color: $themecolor4;
    border: none;
    border-radius: 5px;
}

.downloadTemplateButton{
  border: none;
  cursor: pointer;
  font-family: $defaultfont;
  background-color: #ddd;
  border-radius: 10px;
        // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        // border: none;
  height: 40px;
  width: 100px;
  &:hover {
    background-color: #aaa;
}

}