@import '../../variable';

.container {
  background-color: white;
  width: calc(100% - 3em);
  padding: 1.5em;
  border-bottom: 1px solid #b6b6b6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  align-items: center;

  .wrapperImg {
    display: flex;
    align-items: center;
    gap: 10px;

    .imageBorder {
      background-color: black;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 250px; // 4:3 aspect ratio

      .imageInside {
        width: 200px;
        height: 250px;
        object-fit: cover;
      }
    }
  }

  .panelsubcontenttextfield {
    font-family: KanitRegular;
    border: 0;
    background-color: white;
    font-weight: 400;
    font-size: 15px;
    max-width: 200px;

    ::placeholder {
      font-family: 'Kanit' !important;
    }
    &:focus {
      background-color: #ffdab4;
    }

    &.headerTextField {
      font-size: 32px;
    }
  }
}
