@import "../../variable";
// body{
//     background-color: #E5E5E5;
// }

.menubar{
    padding: 0px 40px;
    background-color: white;
    // margin-top: 20px;
    border-radius: 20px;
    border: $themecolor2 solid 3px;
    box-sizing: border-box;
    // box-shadow: 0px 0px 2px 0px;
    margin: 10px 0px;
    gap: 25px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    // width: 100vw;
    // flex-direction: column;
    // flex-wrap: wrap;
    height: 120px;
    
    @media screen and (max-width: 769px) {
        padding: 10px 25px;
    }
    
    @media screen and (max-width: 426px) {
        overflow-x: scroll;
        padding: 0px 25px;
        gap: 30px;
    }

    .iconLink{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 5px;

        .iconImg {
            width: 55px;
            height: 55px;
            z-index: 2;
    
            @media screen and (max-width: 769px) {
                width: 50px;
                height: 50px;
            }
        }
        
        .iconText {
            text-align: center;
            color: black;
            z-index: 2;
            font-size: 12px;
        }
    }

    // width: 86vw;
}

.menuIcon {
    position: relative;
}

.circle {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%); 
    background: $themecolor2;
    width: 100px;
    height: 100px;
    border-radius: 10px;
    // z-index: ;
}


.verticalline{
    border-left: 0.5px solid #BDBDBD;;
}

.summarybar{
    display: flex;
    background-color: white;
    flex-direction: row;
    padding-top: 100px;
    padding-bottom: 100px;
    box-shadow: 0px 0px 4px 0px;

    .summaryitems{
        flex-grow: 1;
        text-align: center;
    }
}

.adsbar{
    overflow-x: scroll;
    display: flex;
    flex-direction: row;
    gap: 50px;
    flex-wrap: nowrap;
    // margin-top: 50px;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 30px;
}

.lateAbsolute {
    position: absolute;
    top: -20px;
    left: 50px;
}

// .lateAbsolute2 {
//     margin-top: -54px;
//     margin-left: 50px;
//     font-size: 18px;
//     font-weight: bold;

//     background-color: #c4c4c4;
//     border-radius: 10px;

//     display: flex;
//     width: fit-content;
//     justify-content: end;
// }

// .child {
//     background-color: green;
// }