//Profile TeamList
// body{
//     font-family: 'Kanit';
// }
.container{
    display: flex;
    flex-direction: column;
    // justify-content: center;
    align-items: center;
    width: 900px;
    margin: auto;

}
.contentWrapper{
    // width: 900px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 50px;

}
.detailWrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
}
.rowWrapper{
    display: flex;
    gap:20px;

    .leftside{
        display: flex;
        flex-direction: column;
        gap: 15px;
        width: 100%;
        padding-left: 20px;

        .textsubcompany{
            color: #313131;
            font-size: 18px;
        }
        .textsub{
            font-size: 15px;
            color: #313131;
        }
        .textHead{
            font-size: 18px;
            font-weight: bold;
        }
        .textsubflex{
            display: flex;
            gap: 5px;
        }

      
    }

    .rigthtside{
        display: flex;
        gap: 20px;
        flex-direction: column;
        width: 100%;
        .imageGroup{
            display: flex;
            flex-direction: column;
            align-items: center;
            // border: 1px solid red;
            // width: 100%;
            // border: 1px solid red;
            // height: 200px;
        }

        .showfile{
            padding-left: 25px;
            // text-align: right;     
        .textfile{
            cursor: pointer;
            // &:focus,&:hover{
            //     opacity: 0.7;
            //   }
            padding-left: 10px;
            display: flex;
            gap: 5px;
        }
        .fileText{
            // cursor: pointer;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 326px;
        }
        }

    }
}
.topSearchWrapper{
    display: flex;
    justify-content: flex-end;
    
}
.topTableBtnWrapper{
    display: flex;
    gap: 5px;
    justify-content: flex-end;
}
//* Card
.card{
    background-color: #E5E5E5;
    border-radius: 20px;
    padding: 20px 25px ;
    display: flex;
    // width: 100vmin;
    // width: auto;
    flex-direction: column;
    gap: 15px;

    .head{
        display: flex;
        justify-content: flex-end;
        .btDetail{
            cursor: pointer;
        }
    }
}
.verifiedbox{
    display: flex;
    gap: 5px;
    justify-content: flex-end;

    .unverify{
        font-size: 18px;
        color: #EB5757;
    }
}
.textfiel{
    // border: 1px solid red;
    background-color: #EF8834;
}
.tableCard{
    border-radius: 5px;
    background-color: #ffffff;
    padding: 10px;
    box-shadow: 0px 3px 5px rgba(176, 190, 197, 0.32), 0px 8px 24px rgba(176, 190, 197, 0.32);

    .table{
        margin: 10px;
        .emptyTeam {
            // display: flex;
            // align-items: center;
            // border: 1px solid red;
            padding-top: 20px;
            .text{
                text-align: center;
            }
        }
        .saftypass{
            display: flex;
            align-items: center;
            gap: 8px;
            .dotacceptStatus{
                width: 10px;
                height: 10px;
                background-color: #8AC82D;
                border-radius: 100px;
                // border: 1px solid red;
            }
            .dotpendingStatus{
                @extend .dotacceptStatus;
                background-color: #F2C94C;
            }
        }

        .more{
            // cursor: pointer;
            // font-size: 20px;
            // font-weight: bold;
            // color: #F2C94C;
        }
      
        .texthead{
            font-family: 'Kanit';
            font-weight: bold;
        }

        .textsub{
            font-family: 'Kanit';

        }
        .status{
            .ActiveStatus{
                font-family: 'Kanit';
                font-weight: bold;
                color: #50BF0B;
                padding: 5px;
                background-color: #BBEBAF ;
                width: 72px;
                border-radius: 14px;
            }
            .InactiveStatus{
                @extend .ActiveStatus;
                background-color: #E4E4E4;
                color: #484848;
            }
        }
       
    }
}
//* TEXT
.textTopic{
    font-size: 1.5rem;
    font-weight: bold;
}
.textTableTopic{
    font-size: 1.25rem;
    font-weight: bold;
    color: #EF8834;
}
.textSubtopic{
    @extend .textTopic;
    font-size: 1rem;
}
//Input
.searchBox{
    width: 250px;
}
//! Button
.btnImport{
    font-family: 'Kanit';
    width: 150px;
    height: 40px;
    background-color: #F2C94C;
    border-radius: 5px;
    color:#ffffff;
    font-size: 15px;
    // font-weight: bold;
    cursor: pointer;
    border: none;
    position: relative;

    .inputImport {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        opacity: 0;
    }
}
.btnDownload{
    @extend .btnImport;
    background-color:#F27A7A ;
}
.btnAddTeam{
    @extend .btnImport; 
    background-color: #F2994A;
    display: flex;
    align-items: center;
    gap: 5px;
    justify-content: center;
}
//Table 
.btDetail{
    border: none;
    background-color: transparent;
}

.menulist{
    display: flex;
    gap: 10px;
}
.modalBox {
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    // border: 1px solid red;
    
    .cardModal {
        position: relative;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        padding: 0px 40px;
       padding-top: 30px;
        // background: $bg;
        background-color: #ffffff;
        border-radius: 10px;
        width: 900px;
        height: 100%;
        .head{
            border: 1px solid red;
        }
        .textTopic{
            margin: 0;
            font-weight:bold;
            font-size: 1.7rem;
            text-align: center;
        }
        
        .textWrapper{
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: space-around;
        }
        .textContent{
            @extend .textTopic;
            font-size: 1rem;
            font-weight: normal;
        }
       
            .bottom{
                position: absolute;
                bottom: 0px;
                left: 0;
                background-color: white;
                height: 52px;
                width: 100%;
                box-shadow: 0px 3px 5px rgba(176, 190, 197, 0.32), 0px 8px 24px rgba(176, 190, 197, 0.32);
                border-radius: 0px 0px 5px 5px;
            }
            .buttonWrapper{
                padding:5px 15px;
                display: flex;
                justify-content: space-between;
            }
            .btnTwin{
                display: flex;
                gap: 5px;
            }
            //Button
            .btnBox{
                height: 42px;
                min-width: 70px;
            }
            
    }
 

  }


.importCSVModal {
    font-family: 'Kanit';
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    button {
        font-family: 'Kanit';
    }
   
    .importCSVModalContainer {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        background-color: #fff;
        border-radius: 10px;
        padding: 1rem 3rem;
        height: 15rem;

        .importCSVModalContentContainer {
            display: flex;
            flex-direction: column;
            row-gap: 1rem;

            .importCSVModalHeader {
                display: flex;
                justify-content: space-between;
                align-items: center;

                img {
                    cursor: pointer;
                }
                
            }

            .importCSVModalTitle {
                font-size: 24px;
                font-weight: 600;
            }

            .downloadExampleCSV {
                border: none;
                outline: none;
                cursor: pointer;
                color: #EF8834;
                font-weight: 600;
            }

            .importFileValidError {
                font-size: 14px;
                color: #FD3E3E;
                margin-bottom: 4px;
            }
    
            .selectFileContainer {
                border: 1px solid #F2C94C;
                display: flex;
                align-items: center;
                column-gap: 1rem;
                border-radius: 4px;

                .selectFileName {
                    width: 25rem;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

    
                .selectFileBtn {
                    background-color: #F2C94C;
                    position: relative;
                    color: white;
                    outline: none;
                    border: none;
                    border-radius: 4px;
                    padding: 0.3rem 1rem;
    
                    .inputImport {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        cursor: pointer;
                        opacity: 0;
                    }
                }
            }
        }

       

        .importCSVModalButtonActions {
            display: flex;
            justify-content: center;
            column-gap: 1rem;

            button {
                border: none;
                outline: none;
                cursor: pointer;
                padding: 0.3rem 1.5rem;
                border-radius: 4px;
                color: #fff;
            }

            .buttonActionsCancel {
                background-color: #B1B1B1;
            }

            .buttonActionsOk {
                background-color: #EF8834;
            }
        }
        
    }
}