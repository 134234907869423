@import '../../../variable.scss';

.thirdcontentcontainer {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.planActualWrapper {
  display: flex;
  position: relative;
  top: -20px;
  left: -25px;
  gap: 10px;
  height: 50px;
  // background-color: lawngreen;

  &::before {
    position: absolute;
    left: 0px;
    top: 0px;
    content: '';
    background-color: white;
    height: 50px;
    width: 900px;
    z-index: 0;
  }

  .selectionButton {
    position: relative;
    border: none;
    // background-color: transparent;
    cursor: pointer;
    font-family: $defaultfont;
    font-size: 25px;
    font-weight: 500;
    padding: 0px 10px;
    min-width: 150px;
    color: gray;
    // background-color: violet;
    background-color: #a6a6a6;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px;

    &.selected {
      background-color: $themecolor4;
      color: black;
    }
  }
}

.masterplanCard {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .masterplanToolbar {
    display: flex;
    justify-content: space-between;
    .toolbarLeftSide {
      .masterPlanTopic {
        font-size: 30px;
      }
      .selectDropdown {
        font-size: 15px;
        font-family: 'Kanit';
        padding: 0 10px;
        font-weight: bold;
      }
    }

    .masterPlanSelectContainer {
      display: flex;
      column-gap: 20px;
    }

    .toolbarRightSide {
      display: flex;
      gap: 10px;
      align-items: flex-end;

      .masterPlanBtn {
        background-color: $themecolor6;
        padding: 10px 20px;
        color: white;
        border: none;
        border-radius: 10px;
        font-family: 'Kanit';
        cursor: pointer;
      }
    
      .downloadCsvBtn {
        // @extend .masterPlanBtn;
        padding: 10px 20px;
        color: white;
        border: none;
        border-radius: 10px;
        font-family: 'Kanit';
        // background-color: none;
      }
      .createMasterplanBtn {
        @extend .downloadCsvBtn;
        background-color: $themecolor6;
        cursor: pointer;
      }
      .createMasterplanBtn:disabled {
        @extend .downloadCsvBtn;
        background-color: $themecolor3;
      }
      .importBtn {
        @extend .downloadCsvBtn;
      }
      .exportBtn {
        @extend .masterPlanBtn;
        position: relative;
      }
      .headBtn {
        display: flex;
        align-items: center;
        .headImgBtn {
          height: 17px;
        }
        .selectExport {
          position: absolute;
          left: 0px;
          width: 10rem;
          bottom: -4rem;
          font-size: 11px;
          background-color: #ffffff;
          border-radius: 7px;
          overflow: hidden;
          color: #626262;
          .hiddenItem {
            display: flex;
            padding: 5%;
            &:hover {
              background-color: #e4e4e4;
            }
          }
        }
      }
    }
  }
}
