@import "../../../variable";


.addProjectWrapper{
  border: 3px solid $themecolor2;
  border-radius: 20px;
  width: 100%;
  margin: 15px 0px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  
  padding:30px 50px;
  position: relative;

}


.datepickerline{
  display: flex;
  flex-direction: row;
  align-items: baseline;
  
  gap: 40px;
  @media screen and (max-width: 426px) {
      display: block;
    }
  }

  .inputfile{
      width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
      
    .labelfile{
      font-size: 15px;
      font-weight: bold;
      cursor: pointer;
      display: flex;
      &:focus,&:hover{
        opacity: 0.7;
      }
    .primaryuploadspan{
      z-index: 2;
      padding: 5px 15px 5px 15px;
      background: #EF8834;
      border-radius: 56px;
      width: 150px;
      text-align: center;
      
      @media screen and (max-width: 426px) {
        font-size: 8px;
        width: 100px;
      }
  
      @media screen and (max-width: 376px) {
        font-size: 7px;
      //   margin: 3px 0 0 0;
      }
      
      @media screen and (max-width: 321px) {
        font-size: 6px;
      //   margin: 3px 0 0 0;
      }
      
      .uploadlogo{
        width: 20px;
        height: 20px;
        @media screen and (max-width: 426px) {
          width: 10px;
          height: 10px;
        }
      }
  
    }
  .secondaryuploadspan{
    z-index: 1;
    background: rgba(239, 136, 52, 0.15);
    border-radius: 70px;
    color: #949494;
    margin: 0 0 0 -50px;
    padding: 5px 60px;
    width: 100%;

    @media screen and (max-width: 426px) {
      padding: 5px 60px 5px auto;
      font-size: 10px;
    }

    @media screen and (max-width: 376px) {
      font-size: 8px;
    }

    @media screen and (max-width: 321px) {
      font-size: 7px;
    }
  }
}
      
.labelshowfile{
  cursor: pointer;
  &:focus,&:hover{
    opacity: 0.7;
  }
  .filelogo{
    width: 40px;
    height: 40px;
    @media screen and (max-width: 426px) {
      width: 30px;
      height: 30px;
    }
  }
}
.projectmanagerline{
  display: flex;
  gap: 50px;
  @media screen and (max-width: 426px) {
      display: block;
      margin: 0 0 10px 0;
    }
  }
  
  //cardCustomFields
  .cardCustomFields{
    background-color: #F2F2F2;
    border-radius: 5px;
    padding:  20px 30px;
    .rowWrapper{
      display: grid;
      grid-template-columns: 1fr 1fr;
      column-gap: 70px;
      align-items: center;
    }
    //* TEXT
    .textTopic{
      font-size: 1.5rem;
    }
    .title{
      font-size: 1rem;
      color:#828282;
    }
    .inputWrapper{
      display: flex;
      flex-direction: column;
     
      gap: 5px;
    }
   
    .inputField{
      border-radius: 10px;
      outline: none;
      border: 1px solid #EF8834;
      background-color: #ffffff;
      height: 45px;
      padding-left: 10px;
      font-size: 1.5rem;
      width: 100%;
    }
    .inputAdd{
      position: relative;
      @extend .inputField;
      border: none;
      background-color:#E0E0E0;
     
  }
  .inputSelect{
    @extend.inputAdd;
    background-color:#FFCDA4;
  }
  .inputFieldContainer{
    position: relative;
    display: flex;
    align-items: center;
  }
    .field{
      @extend .inputField;
      background-color:#FFCDA4 ;
      .close{
        cursor: pointer;
        position: absolute;
        // top: 7px;
        right: 8px;
      }
      .edit{
        @extend .close;
        right: 40px;
      }
      .image{
        width: 20px;
        height: 20px;
      }
    }
    .close{
      cursor: pointer;
      position: absolute;
      // top: 7px;
      right: 8px;
      display: flex;
      align-items: center;
      width: 16px;
      height: 16px
    }
    
    .inputValues{
      position: relative;
      outline: none;
      border: none;
      background-color: #FFCDA4;
      width: 100%;
      padding-left: 10px;
      height: 30px;
    }
    .inputValuesBGWhite{
      @extend .inputValues;
      background-color: #ffffff;
     
    }
    .inputAllowed{
      border: 1px solid #EF8834;
      margin:10px 0;
      outline: none;
      width: 100%;
      height: 25px;

    }
  }
  .btnAddList{
    width: fit-content;
    cursor: pointer;
    border: transparent;
    background: none;
    display: flex;
    align-items: center;
    gap: 10px;
    .text{
      color:#828282;
    }
  }
  .inputAllowedWrapper{
    position: relative;

    .closeIcon{
      cursor: pointer;
      position: absolute;
      right: 5px;
      top: 5px;
    }
  }
  .fieldNameWrapper{

  }
  .inputFieldNameWrapper{
    position: relative;
    .closeIcon{
      cursor: pointer;
      position: absolute;
      right: 8px;
      top: 15px;
    }
  }

.dropDownInput{
  min-width: 250px; 
  font-size: 20px;
  padding: 5px 0;
}