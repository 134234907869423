@import "../../variable";

.imageSlideShow{
    position: relative;
    .imageShow{
        display: flex;
        flex-direction: column;
        min-width: 390px;
        max-width: 390px;
        min-height: 221px;
        max-height: 221px;
        background-color: $lightgraycolor;
    }
    .btnContainer{
        position: absolute;
        bottom: 5px;
        display: flex;
        width: 100%;
        justify-content: center;
        gap: 10px;
        .btnSelectImage{
            background-color: #FFFFFF;
            width: 20px;
            height: 20px;
            border-radius: 100%;
            border: 1px solid $themecolor3;
            // border: none;
            cursor: pointer;
        }
    }
}