@import "../../../variable";

.modalWrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    
    transform: translate(-50%, -50%);

    .modalContainer {
        background-color: #F2F2F2;
        width: 42rem;
        height: 35rem;
        padding: 2rem;
        border-radius: 10px;
    
        .modalHeader {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h1 {
                font-size: 24px;
            }

            .closeButton {
                padding: 4px;
                background-color: transparent;
                border: none;
                cursor: pointer;
                img {
                    width: 28px;
                    height: 28px;
                }
            }
        }

        .subHeader {
            margin: 8px 18px 24px 18px;
            font-weight: 600;
        }


        

        .modalContent {
            background-color: #FFFFFF;
            padding: 1rem;
            border-radius: 10px;
            height: 20rem;

            .contentInputWrapper {
                padding-bottom: 10px;
                display: flex;
                flex-direction: column;
                row-gap: 16px;
                height: 16rem;
                overflow-y: scroll;

                .inputContainer {
                    display: grid;
                    // position: relative;
                    grid-template-columns: 1fr 3fr;
                    margin: 0 6rem;
    
                    .inputLabel {
                        font-size: 18px;
                        font-weight: 600;
                        white-space: nowrap; 
                        width: 5.2rem; 
                        overflow: hidden;
                        text-overflow: ellipsis; 
                    }
    
                    input[type="number"] {
                        border-radius: 10px;
                        border: 2px solid #BBBBBB;
                        padding: 8px 12px;
                        outline: none;
    
                        &:focus {
                            border: 2px solid #000000; 
                        }
                    }
                }
            }

            .divider {
                margin-top: 4px;
                width: 100%;
                height: 1px;
                background-color: #BBBBBB;
            }

            .total {
                margin-top: 10px;
                font-size: 18px;
                font-weight: 600;
                span {
                    margin-left: 10px;
                }
            }

            
        }

        .modalActionContainer {
            margin-top: 24px;
            display: flex;
            justify-content: center;
            column-gap: 14px;
            button {
                box-shadow: $boxShadow1;
                width: 14rem;
                border: none;
                padding: 8px;
                cursor: pointer;
                font-size: 18px;
                border-radius: 5px;
                font-family: $defaultfont;
            }

            .cancelButton {
                background-color: #F7F7F7;
                font-weight: 600;
            }

            .saveButton {
                background-color: #EFC634;
            }
        }
    }
}
