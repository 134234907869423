.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .cardforgot{
        background: #F8D8BD;
        box-shadow: 0px 3px 5px rgba(176, 190, 197, 0.32), 0px 8px 24px rgba(176, 190, 197, 0.32);
        border-radius: 5px;
        height: 419px;
        width: 900px;
        .title{
            padding: 20px;
            
            .texthead{
                font-size: 23px;
            }

        }
        .input{
            padding: 30px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            align-items: center;
            .GroupInput{
                display: grid;
                grid-template-columns: 200px 400px;
                // border: 1px solid red;
                // gap: 10px;
                .inputfiled {
                    width: auto;
                    height: 37px;
                    background-color: white;
                    border: 1px solid #828282;
                    border-radius: 5px;
                    box-sizing: border-box;
                    font-family: 'Kanit';
                  }

                  .errorBox{
                    //  padding-left: 5rem;
                     color: #F55353;
                     font-size: 12px;
                     text-align: right;
                     font-weight: 500;
                    }
            }

            .forgotBox{
                text-decoration-line: underline;
                display: flex;
                flex-direction: column;
                font-weight: bold;
                font-size: 15px;
                color:#F2994A ;
                text-align: right;
                cursor: pointer;
            
            }
           
        }
       
    }

.button{
    padding-top: 20px;
   //! button 
   .btnFooterWrapper{
    font-family: 'Kanit';
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}

.btnCancle{
    font-size: 1rem;
    width: 240px;
    font-family: 'Kanit';
    height: 40px;
    background-color: #BDBDBD;
    border: none;
    border-radius: 5px;
    color:#ffffff;
    cursor: pointer;
}
.btnConfirm{
    @extend .btnCancle;
    background: #F2994A;
    color:black;  
}
    }
 
}


.modalBox {
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  
    .card {
      position: relative;
      display: flex;
      flex-direction: column;
    //   padding: 30px 40px;
      background: #FFCDA4;
      border-radius: 10px;
      width: 500px;
      height: 350px;
      filter: drop-shadow(0px 3px 5px rgba(176, 190, 197, 0.32)) drop-shadow(0px 8px 24px rgba(176, 190, 197, 0.32));
   
  
      
      .head {
        background-color: white;
        height: 56px;
        box-shadow: 0px 3px 5px rgba(176, 190, 197, 0.32), 0px 8px 24px rgba(176, 190, 197, 0.32);
        border-radius: 5px 5px 0px 0px;
        .textHead {
          font-size: 24px;
          text-align: center;
          margin: 10px;
          color: #F2994A;
        }
  
        .Closeicon {
          position: absolute;
          cursor: pointer;
          margin: -39px 28rem 0;
        }
      }

      .description{
          padding-top: 5rem;
          text-align: center;
      }
      .bottom {
        position: absolute;
        bottom: 0px;
        margin-top: 10rem;
        background-color: white;
        height: 56px;
        width: 500px;
        box-shadow: 0px 3px 5px rgba(176, 190, 197, 0.32), 0px 8px 24px rgba(176, 190, 197, 0.32);
        border-radius: 0px 0px 5px 5px;
      }
      .btnForm{
          display: flex;
          flex-direction: column;
          align-items: center;
          padding-top: 6px;
        .btClose{
            font-size: 1rem;
            width: 240px;
            font-family: 'Kanit';
            height: 40px;
            background-color: #F2994A;
            border: none;
            border-radius: 5px;
            cursor: pointer;
        }

      }
       
      
    }
  
    .from {
      margin: 20px;
    }

  }