@import '../../variable';

.container {
  background-color: white;
  width: calc(100% - 3em);
  padding: 1.5em;
  border-bottom: 1px solid #b6b6b6;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  @media screen and (max-width: 769px) {
    width: 100%;
  }

  .wrapperData {
    display: flex;
    flex-direction: column;
    gap: 24px;

    .header {
      display: flex;
      gap: 16px;
      width: 100%;
      justify-content: space-between;

      .wrapLeft {
        display: flex;
        width: 70%;
        gap: 8px;
      }
    }

    .flexBox {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .wrapperSelect {
      display: grid;
      width: 100%;
      grid-template-columns: auto 1fr;
      align-items: center;
      gap: 8px;
    }
  }
  .text {
    font-weight: bold;
  }
  .wrapperCheck {
    display: flex;
    gap: 16px;
  }

  .selectInput {
    padding: 0.3em;
    font-family: KanitRegular;
    border: 0;
    font-size: 16px;
    border-radius: 4px;
    width: 100%;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .wrapperBox {
    display: grid;
    grid-template-columns: 1fr auto 1fr;
    grid-column-gap: 16px;
    @media screen and (max-width: 769px) {
      display: flex;
      flex-direction: column;
      gap: 16px;
    }
    .boxGrid {
      display: flex;
      flex-direction: column;
      gap: 8px;
      .wrapperSelect {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        gap: 11px;
      }
    }

    .wrapperAreaText {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .wrap {
      align-self: flex-end;
      display: grid;
      gap: 8px;
      align-items: center;
      grid-template-columns: auto 1fr;
    }
    .wrapperData {
      display: flex;
      flex-direction: column;
      gap: 24px;
      .wrapperInput {
        display: grid;
        grid-template-columns: 1fr auto;
        align-items: flex-end;
        grid-column-gap: 8px;
      }

      .wrapperRow {
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        grid-column-gap: 10px;
        .groupIcon {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            cursor: pointer;
          }
        }
      }
    }
    .line {
      border-left: 1px solid #ececec;
    }
  }

  .box {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .wrapperBt {
    border: 0;
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    background-color: white;
    border-radius: 4px;
    padding: 0.5em 1em;
    font-size: 16px;
    width: 100%;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
    .group {
      display: flex;
      gap: 10px;
    }
  }

  .main {
    display: flex;
    gap: 8px;
    @media screen and (max-width: 769px) {
      flex-direction: column;
      gap: 30px;
    }

    .wrapBox {
      display: flex;
      flex-direction: column;
      gap: 24px;
      width: 60%;
      @media screen and (max-width: 769px) {
        width: 100%;
      }
      .boxInput {
        display: grid;
        grid-template-columns: 1fr auto;
        gap: 8px;
        align-items: center;
        .icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        }
      }

      &:last-child {
        width: 40%;
        @media screen and (max-width: 769px) {
          width: 100%;
        }
      }
    }
  }

  .boxBottom {
    display: flex;
    flex-direction: column;
    gap: 8px;
    .group {
      display: flex;
      gap: 8px;
      .groupIcon {
        display: flex;
        gap: 8px;
      }
    }
    .boxInput {
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;
      grid-column-gap: 8px;
    }
    .groupIcon {
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        cursor: pointer;
      }
    }
  }
}
