
//Profile
.container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

}
.contentWrapper{
    width: 900px;
    background-color: #F2994A;
    padding: 30px;


    .card{
        background-color: #ffffff;
        padding:  50px 20px;
        border-radius:5px;
        display: flex;
        flex-direction: column;
        gap: 40px;
        position: relative;

        .btnWrapper{
          display: flex;
          gap: 10px;
          // align-items: flex-end;
          // border: 1px solid red;
          justify-content: flex-end;
          .btnEdit{
            cursor: pointer;
            border: transparent;
            // padding: 10px 15px;
            width: 100px;
            height: 40px;
            color:#ffffff;
            font-family: 'Kanit';
            background-color: #F2994A;
            border-radius: 5px;
        }
        .btnBack{
          @extend .btnEdit;
        }
        }

        .workbox{
            display: flex;
            gap: 10px;
                .boxInput{
                    
                   background-color:  #F2F2F2;
                   border-radius: 15px;
                   font-size: 16px;
                   padding: 5px;
                }
           
        }
        
        .TypeInpitBox{
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .bottom{
                .texthead{
                    font-size: 18px;
                    font-weight: bold;
                }
                display: flex;
                gap: 20px;
                align-items: center;
            }
        }
      

       

        .verifyFristContainer{
            width: 900px;
            display: flex;
            flex-direction: column;
            gap: 30px;
            // margin: 20px;
            padding: 30px;
            .flexbox{
              display: flex; 
              gap: 30px;
        
              .inputleft{
         
                width: 100%;    
        
                .inputArea{
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
                  .flexinput{
                    display: flex;
                    gap: 10px;
                  }
        
                  .addressBox{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                  }
                }
               
              }
              .inputrigth{
                padding-top: 10px;
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: 15px;
        
                .upload{
                  display: flex;
                  flex-direction: column;
                  // gap: 5px;
                }
        
             
        
                .radioGroup{
                  display: flex;
                  flex-direction: column;
                  gap: 10px;
             
                  .radio{
                    display: flex;
                    gap: 20px;
        
        
                  }
        
              
                }
        
                
        
              }
            }
        
            .emerBox{
              display: flex;
              flex-direction: column;
              gap: 10px;
              padding-top: 20px;
        
              .flexinput{
                display: flex;
                gap: 10px;
        
                .GroupInput{
                  width: 100%;
                  display: flex;
                  flex-direction: column;
        
                  .flexInput{
                    gap: 20px;
                    display: flex;
                  }
                }
              }
            }
            .inputWrapper{
                display: flex;
                flex-direction: column;
                gap: 20px;
            }
            .radioWrapper{
                display: flex;
                gap: 50px;
            }
            .textTitle{
        
                font-size: 1.5rem;
            }
            .selectWrapper{
              display: grid;
              grid-template-columns: 1fr 1fr;
              justify-items: stretch;
              align-items: stretch;
              column-gap: 20px;
              row-gap: 20px;
            }
            .rowWrapper{
              display: flex;
              align-items: baseline;
              gap:20px;
            }
            .inputRowBox{
              display: grid;
              grid-template-columns: 1fr 1fr;
              align-items: end;
              column-gap: 20px;
              // display: flex;
              // align-items: flex-end;
              // justify-content: space-between;
              // gap:20px;
              // width: 600px;
            }
        
            .texthead{
                font-weight: bold;
                font-size: 18px;
              }
              
            .textsub{
                font-size: 18px;
                text-decoration: underline;
                font-weight: bold;
                color: #F2994A;
              }
        
              .title{
                // text-align: center;
                font-size: 35px;
                font-weight: bold;
                color: #F2994A;
              }
        }
    }
}
.detailWrapper{
    display: flex;
    gap:50px;
}
.addressWrapper{
    width: 300px;
    text-align: justify;
    text-justify: inter-word;
}
.contractWrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;

}
.inputUserName{
    outline: none;
    height: 30px;
    border: 1px solid #BBBBBB;
    border-radius: 5px;
    padding-left: 5px;
}

//* TEXT
.textIconWrapper{
   display: flex;
   gap:10px;
}
.textTopic{
    font-size: 1.5rem;
}
.textAddress{
    padding-left: 20px;
    color:#626262;
}
.textTitle{
    font-size: 1rem;
}
.textTitleLeft{
    padding-left: 15px;
    font-size: 1rem;
}
.textSubTitle{
    color:#626262;
}
.textStatusReject{
    color: #FD0000;
    font-size: 1rem;
}
.textStatusAccept{
    color: #9BF00F;
}
//* Button
.btnChangePassword{
    font-family: 'Kanit';
    font-size: 1rem;
    background: none;
    border: transparent;
    text-decoration: underline;
    color: #F2994A;
    cursor: pointer;
}






