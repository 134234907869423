@import "../../../../variable";

.rightSideContent{
    background-color: $lightgraycolor;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-basis: 45%;
    justify-content: space-between;

    .verifyContent{
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 15px;

        .topicGroup{
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 5px;

            .verifyTopic{
                font-size: 18.72px;
            }
            .verifySubTopic{
                font-size: 15px;
            }
            .showingPhoneNum{
        
            }
        }
        .verifyContentItems{
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 20px;

            .digitLine{
                display: flex;
                gap: 5px;
                .digitBox{
                    background-color: transparent;
                    border: none;
                    border-bottom: 2px solid black;
                    width: 25px;
                    text-align: center;
                }
            }
        }
        .description{
    
        }
    }
    .loginBtn{
        background-color: $themecolor2;
        border: none;
        width: 100%;
        padding: 5px 0;
        cursor: pointer;
        font-family: "Kanit";
    }
}