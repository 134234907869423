@import "../../_variable.scss";


.createPostContainer{
    padding: 30px 40px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    background-color: #FFCDA4;
    border-radius: 20px;

    .topic{
        display: flex;
        justify-content: space-between;
        align-items: center;
        .topicText{

        }
        .topicIcon{
            cursor: pointer;
        }
    }
    .subject{
        display: flex;
        padding: 20px 30px;
        // justify-content: space-between;
        background-color: #FFCDA4;
        border: 3px solid #F2994A;
        border-radius: 20px;
        gap: 50px;

        .leftSideSubject{
            display: flex;
            flex-direction: column;
            gap: 20px;
            flex-basis: 50%;

            .subjectTopicLine{
                display: flex;
                flex-direction: column;
                .subjectTopic{

                }
            }
            .subjectDateRow{
                display: flex;
                gap: 20px;
                .subjectDateColumn{
                    flex-basis: 50%;
                    .subjectDateTopic{

                    }
                }
            }
            .subjectDescriptionContainer{

                .subjectDescription{
                    padding: 5px;
                    resize: none;
                    width: 100%;
                    height: 60px;
                    border-radius: 10px;
                }
            }
        }
        .rightSideSubject{
            
            flex-basis: 50%;
            .imgUploadeContainer{

                .imageDescription {
                    font-size: 12px;
                }

                .imgUploader{

                }
                .imgShow{

                    .smallImg{

                    }
                }
            }
            .fileContainer{
                flex-direction: column;
                .fileLine{
                    display: flex;
                }
            }
        }
    }
    .content{
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 20px;
        background-color: white;
        border-radius: 20px;

        .contentTopic{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .contentTopicLeftSide{
                display: flex;
                gap: 20px;
                align-items: center;
                .contentTopicText{

                }
                .checkboxContainer{
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    .linkJobCheckbox{
                        // color: #F2994A;
                        // border-color: #F2994A;
                        // &::before{
                        //     border-color: #F2994A;
                        // }
                        // &::after{
                        //     color: #F2994A;
                        //     border-color: #F2994A;
                        // }
                    }
                }
            }
            .contentTopicRightSide{

            }
        }
        .contentTableAndImg{
            display: flex;
            gap: 20px;
            .tableLeftSide{
                flex-basis: 50%;
            }
            .tableRightSide{
                flex-basis: 50%;
            }
        }
        .footerTableContainer{

        }
    }
    .submitBtnGroup{
        display: flex;
        justify-content: center;
        gap: 30px;
        
        .cancelBtn{
            width: 300px;
            height: 30px;
            background: #E0E0E0;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
            border: none;
            cursor: pointer;
            font-family: "Kanit";
            font-weight: bold;
            border-radius: 5px;
        }
        .submitBtn{
            width: 300px;
            height: 30px;
            background: #FF9C41;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
            border: none;
            cursor: pointer;
            font-family: "Kanit";
            font-weight: bold;
            border-radius: 5px;
        }
    }
}

.inputBox{
    width: 100%;
    border-radius: 5px;
    height: 30px;
    font-size: 15px;
    border: 1px solid black;
}


.modalExample{
    // width: 90vw;
    border-radius: 20px;
    padding: 30px 20px;
    position: relative;
    font-family: 'Kanit', sans-serif;
    .innerContainer{
        display: flex;
        flex-direction: column;
        gap: 20px;

        .title{
            display: flex;
            justify-content: space-between;
        }
        .contentCard{
            border-radius: 20px;
            // background-color: transparent;
            // padding: 10px 20px;
            display: flex;
            flex-direction: column;
            background-color: $lightgraycolor;
            // gap: 2px;
            .modalHeader{
                display: flex;
                justify-content: space-between;
                padding: 10px 20px 5px 20px;
                .leftSide{
                    display: flex;
                    flex-direction: column;
                    // gap: 5px;
                    .leftSideTopic{
                        font-size: 20px;
                    }
                }
                .rightSide{
                    display: flex;
                    flex-direction: column;
                    justify-content: flex-end;
                    align-items: flex-end;
                }
            }
            .separateLine{
                border: none;
                border-bottom: 2px solid white;
                width: 100%;
            }
            .modalContent{
                padding: 5px 20px 60px 20px;
                display: flex;
                justify-content: space-between;
                .leftSide{
                    flex-basis: 50%;
                    position: relative;
                    .description{
                        
                    }
                    .provinceContainter{
                        position: absolute;
                        bottom: 0px;
                    }
                }
                .rightSide{
                    flex-basis: 50%;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    gap: 40px;
                    .dateCreated{

                    }
                }
            }
        }
    }
}

.fileContainer{
    display: flex;
    align-items: center;
    gap: 10px;
    .fileImg{
        width: 25px;
    }
}

.applyJobfileContainer{
    flex-direction: column;
    .fileLine{
        cursor: pointer;
        display: flex;
        gap: 10px;
    }
}

.fileText{
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 326px;
}

.inputLabel {
    font-size: 16px;
    margin-bottom: 4px;
}

.safetyPassport {
    margin-top: 10px;

    .safetyPassportRadioGroup {
        padding: 4px 0; 
    }
}

.modalSelect{
    background-color: $lightergraycolor;
    padding: 35px 25px;
    border-radius: 20px;
    height: 560px;
    overflow: hidden;
    .innerContainer{
        display: flex;
        flex-direction: column;
        gap: 20px;

        .title{
            display: flex;
            justify-content: space-between;
        }
        .contentCard{
            background-color: white;
            padding: 30px 20px;
            border-radius: 20px;
            display: flex;
            flex-direction: column;

            .contentCardHeading {
                margin-bottom: 10px;
            }

            .technicianTableContainer {
                max-height: 150px;
                overflow-y: scroll;
            }

            .inputSection{
                display: grid;
                grid-template-columns: 1fr 1fr 1.5fr;
                gap: 20px;

                .technicianWorkType {
                    grid-column: 2 / span 2;
                }

                .inputGroup{
                    display: flex;
                    flex-direction: column;
                    // justify-content: space-between;
                    width: 100%;

                    .inputLine{
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }
                }

               

                .searchName {
                    font-size: 14px;
                    padding: 8.25px;
                    border: 1px solid #BBBBBB;
                    border-radius: 4px;
                }
            }
        }
    }
}