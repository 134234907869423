@import '../../variable';

.subCard {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 1em;
  background-color: white;
  flex-direction: column;

  .flexImg {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .title {
    font-weight: bold;
    text-align: center;
  }

  .rowElement {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .rowUploadText {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .textValue {
      background-color: $themecolor1;
      padding: 0px 10px;
    }
  }

  .wrapperInput {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 10px;
    grid-row-gap: 16px;
    &[aria-checked='true'] {
      grid-template-columns: repeat(4, 1fr);
    }
  }

  .inputDisbles {
    padding: 0.4em;
    border-radius: 4px;
    background-color: #ececec;
    max-width: 227px;
    width: 100%;
  }

  .bottomGroup {
    display: flex;
    gap: 16px;
    align-items: center;
  }
}
