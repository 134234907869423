@import "../../../../variable";

.rightSideContent{
    background-color: $lightgraycolor;
    padding: 20px 20px 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 45%;
    gap: 20px;
    text-align: center;
    align-items: center;

    .successTopic{
        font-size: 18.72px;
    }
    .successlogo{
        width: 100px;
    }
    .phonenumHighlight{
        color: $themecolor2;
    }
}