@import "../../../variable";

.rightSideContent{
    background-color: $lightgraycolor;
    padding: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    flex-basis: 45%;
    @media screen and (max-width: 426px) {
        background-color: transparent;
    }
    @media screen and (max-width: 321px) {
        padding: 20px 0;
    }
    .rightSideTopicContainer{

        @media screen and (max-width: 426px) {
            display: none;
        }
        .rightSideTopic{

        }
    }
    .rightSideItems{
        display: flex;
        flex-direction: column;
        gap: 30px;
        padding: 20px;

        .textFieldGroup{
            display: flex;
            flex-direction: column;
            gap: 20px;

        }
        .errorLine{
            display: flex;
            align-items: center;
            padding: 10px 20px;
            border: 1px solid red;
            gap: 10px;

            .errorImg{

            }
            .errorText{
                font-size: 15px;
            }
        }
        .loginBtnContainer{

            .loginBtn{
                background-color: $themecolor2;
                border: none;
                width: 100%;
                padding: 5px 0;
                cursor: pointer;
                @media screen and (max-width: 426px) {
                    font-family: "Kanit";
                    border-radius: 10px;
                    font-size: 20px;
                    color: white;
                    padding: 15px 0;
                }
            }
            .linkLine{
                display: flex;
                justify-content: space-between;
                .linkitems{
                    text-decoration: underline;
                    font-size: 10px;
                    color: #0A98B7;
                    border: none;
                    background-color: transparent;
                    cursor: pointer;
                }
                @media screen and (max-width: 426px) {
                    display: none;
                }
            }
        }
        .btnContainer{

            @media screen and (max-width: 426px) {
                display: none;
            }
            .orLine{
                display: flex;
                gap: 10px;
                align-items: center;
                hr{
                    width: 100%;
                    margin: 0;
                }
            }
            .idpsBtnGroup{
                display: flex;
                justify-content: space-between;

                .idpsBtn{
                    background-color: #3679EA;
                    border: none;
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 3px 10px;
                    cursor: pointer;
                    flex-basis: 45%;
                    width: 100%;
                    color: white;

                    .idpsLogo{
                        width: 20px;
                        height: 20px;
                    }
                }
            }
            .registerline{
                display: flex;
                justify-content: space-between;

                .registertext{
                    font-size: 10px;
                }
                .registerBtn{
                    color: $themecolor2;
                    font-size: 10px;
                    text-decoration: underline;
                }
            }
        }
    }
}