@import "../../variable";

.termsBlock{
    display: flex;
    flex-direction: column;
    gap: 40px;

    .termsTopicGroup{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .termsPics{
            width: 100%;
            @media screen and (max-width: 426px) {
               object-position: -450px -179px;
               object-fit: none;
            }
        }
        .frontGround{
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
        }
        .termsTextContainer{
            position: absolute;
            text-align: center;
            .termsText{
                color: white;
                font-size: 40px;
                @media screen and (max-width: 426px) {
                    font-size: 20px;
                 }
            }
        }

    }
    .termsContent{
        display: flex;
        flex-direction: column;
        gap: 20px;
        padding: 0 0 30px 0;

        .termContentTopic{

        }
        .termsItems{
            display: flex;
            flex-direction: column;
            gap: 10px;

            .termsItemsTopic{
    
            }
            .termsItemsContent{
                margin: 0 0 0 40px;
                position: relative;

                .contentIndex{
                    position: absolute;
                    left: -20px;
                }
                .contentElementText{

                }
                .subContentContainer{
                    display: flex;
                    flex-direction: column;
                    gap: 30px;
                    margin: 30px 0 30px 40px;
                    position: relative;

                    .subContentIndex{
                        position: absolute;
                        left: -40px;
                    }
                    .subContentText{

                    }
                }
            }
        }
    }  
}