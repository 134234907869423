@import "../../../variable";

.leftSideContent{
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    flex-basis: 60%;

    .leftSideTopicContainer{
        width: 100%;
        cursor: pointer;

        .leftSideTopic{
            color: $themecolor2;
            font-size: 40px;

            @media screen and (max-width: 426px) {
                display: none;
            }
        }
    }
    .changtumlogo{ 
        cursor: pointer;
        width: 200px;
    }
    .changtumdescription{
        text-align: center;
        width: 60%;
        font-size: 20px;

        @media screen and (max-width: 426px) {
            display: none;
        }
    }
    .changtumdescription2{
        font-size: 20px;

        @media screen and (min-width: 426px) {
            display: none;
        }
    }
}