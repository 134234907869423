@import '../../variable';

.container {
  padding-left: 0.8em;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .group {
    display: flex;
    align-items: center;
    gap: 5px;
    .wrapperForm {
      display: flex;
      gap: 5px;
      width: 100%;
      .group {
        max-width: 70px;
        width: 100%;
        color: #c5c5c5;
        display: flex;
        justify-content: flex-end;
        gap: 2px;
        .icon {
          display: flex;
          flex-direction: column;
          align-items: center;
          cursor: pointer;
        }
      }
      img {
        cursor: pointer;
      }
    }

    .box {
      width: 29px;
      height: 24px;
    }
    .icon {
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      transition: transform linear 0.1s;

      &[aria-checked='true'] {
        transform: rotate(180deg);
      }
    }
  }
  .panelcheckboxconfigtopic {
    font-family: 'Kanit';
    border: 0.5px solid #ececec;
    padding: 0.4em;
    background-color: #fafafa;
    font-weight: 400;
    font-size: 16px;
    border-radius: 4px;
    width: 100%;
    ::placeholder {
      font-family: 'Kanit';
    }
    &:focus {
      background-color: #ffdab4;
    }
  }
}
