@import "../../variable";

.projectWrapper{
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;

    .projectHeader{
        display: flex;
        gap: 20px;
        width: 100%;

        .projectList{
            padding: 10px 0;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            background-color: $themecolor4;
            border-radius: 10px;
            // gap: 5px;
            // flex-basis: 20%;
            flex: 1 0 0;

            overflow-y: scroll;
            // overflow-x: scroll;
            height: 170px;

            .projectListHeader{
                padding: 0 15px;
                font-size: 15px;
                font-weight: 500;
                margin-bottom: 8px;
            }
            .projectlistcontent{
                background-color: transparent;
                border: none;
                text-align: left;
                padding: 0 0 0 8px;
                cursor: pointer;
                font-size: 14px;
                &.projectlistcontentSelected{
                    background-color: $themecolor2;
                }
            }
        }
        .projectHeaderButtonWrapper{
            display: flex;
            gap: 20px;
            padding: 20px 30px;
            // flex-basis: 80%;
            flex: 3 0 0;
            background-color: $themecolor4;
            border-radius: 10px;

            .projectHeaderButton{
                font-weight: bold;
                border: none;
                background-color:white;
                color: $themecolor3;
                // flex-basis: 33%;
                flex: 1 0 0;
                font-family: $defaultfont;
                font-size: 23px;
                font-weight: 600;
                border-radius: 5px;
                cursor: pointer;

                &.projectHeaderButtonSelected{
                    background-color: $themecolor2;
                    color: black;
                }
            }
        }
    }
    .projectContent{
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: $themecolor4;
        padding: 20px 25px;
        border-radius: 10px;
    }
}
