//Profile
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.contentWrapper {
  width: 900px;
  background-color: #f2994a;
  padding: 30px;

  .card {
    background-color: #ffffff;
    padding: 50px 20px;
    border-radius: 5px;
    display: flex;
    gap: 40px;
    position: relative;
    // border: 1px solid red;
  }
}
.detailWrapper {
  display: flex;
  gap: 50px;
}

.patListWrapper {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .patButton {
    display: grid;
    grid-template-columns: 5fr 1fr 1fr;
    min-height: 1rem;
    border-radius: 0.25rem;
    background-color: #FAFAFA;
    border: 1px solid #ECECEC;
    cursor: pointer;
    font-size: 0.75rem;

    &:hover{
      background-color: #EAEAEA;
    }
  }
}

.addressWrapper {
  width: 300px;
  text-align: justify;
  text-justify: inter-word;
}
.contractWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.inputUserName {
  outline: none;
  height: 30px;
  border: 1px solid #bbbbbb;
  border-radius: 5px;
  padding-left: 5px;
}

//* TEXT
.textIconWrapper {
  display: flex;
  gap: 10px;
}
.textTopic {
  font-size: 1.5rem;
}
.textAddress {
  padding-left: 20px;
  color: #626262;
}
.textTitle {
  font-size: 1rem;
}
.textTitleLeft {
  padding-left: 15px;
  font-size: 1rem;
}
.textSubTitle {
  color: #626262;
}
.textStatusReject {
  color: #fd0000;
  font-size: 1rem;
}
.textStatusAccept {
  color: #9bf00f;
}
//* Button
.btnChangePassword {
  font-family: 'Kanit';
  font-size: 1rem;
  background: none;
  border: transparent;
  text-decoration: underline;
  color: #f2994a;
  cursor: pointer;
}
.btnEditProfile {
  cursor: pointer;
  border: transparent;
  padding: 10px 15px;
  color: #ffffff;
  background-color: #f2994a;
  border-radius: 5px;
  position: absolute;
  top: 15px;
  right: 20px;
}
.logoImg{
    width: 158px;
    height: 104px;
}

.modalBox {
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: none;
    
    .cardModal {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        // justify-content: center;
        padding: 0px 20px;
        padding-top: 15px;
        background-color: #ffffff;
        border-radius: 10px;
        width: 305px;
        height: 350px;
        border: none;
        overflow-y: scroll;

        .head{
            display: flex;
            justify-content: space-between;
            .textHead {
                font-size: 12px;
                font-weight: bold;
            }
        }
        .modalLogo{
            width: 260px;
            height: 156px;
        }
        .btnUpload{
            font-family: 'Kanit';
            background-color: transparent;
            color: #F2994A;
            // padding: 50px auto;
            height: 40px;
            border: 1px solid #F2994A;
            border-radius: 10px;
            cursor: pointer;
        }
        .btnDeleteUpload{
            font-family: 'Kanit';
            cursor: pointer;
            background-color: transparent;
            color: #2F80ED;
            text-decoration: underline;
            border: none;
        }
        .bottomBtnGroup{
            display: flex;
            gap: 10px;

            .btnCancel{
                font-family: 'Kanit';
                background-color: transparent;
                color: #F2994A;
                // padding: 50px auto;
                height: 40px;
                border: 1px solid #F2994A;
                border-radius: 10px;
                cursor: pointer;
                flex-basis: 50%;
                width: 100%;
            }
            .btnSubmit{
                font-family: 'Kanit';
                background-color: #F2994A;
                color: white;
                // padding: 50px auto;
                height: 40px;
                border: 1px solid #F2994A;
                border-radius: 10px;
                cursor: pointer;
                flex-basis: 50%;
                width: 100%;
            }
        }
    }
    .cardModal::-webkit-scrollbar {
        // display: none;
        width: 5px;
        // background: red; 
        border-radius: 5px;
      }
      .cardModal::-webkit-scrollbar-thumb {
        background: #FFCDA4; 
        border-radius: 50px;
        padding: 5px;
      }
      
    

  }


  .button {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.1rem;
    cursor: pointer;
    font-family: Kanit;
    font-size: 0.9rem;
    img {
      width: 1.2rem;
      height: 1.2rem;
    }

    &.red {
      color: red;
    }
  }