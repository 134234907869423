@mixin flexXYAdjust($x,$y) {
    display: flex;
    align-items: $x;
    justify-content: $y;
}

$defaultfont : "Kanit";

@mixin flexAxisGap($axis,$gap) {
    display: flex;
    flex-direction: $axis;
    gap: $gap;
}

$bg : #E5E5E5;
$themecolor0 : #FFFF;
$themecolor1 : #EFEFEF;
$themecolor2 : #FF8206;
$themecolor2a50: #FF820677;
$themecolor3 : #9A9797;
$themecolor4 : #DFDFDF;
$themecolor5 : #EFC634;
$themecolor5a50 : #EFC63477;
$themecolor6: #EF8834;
$themecolor7: #FEB172;
$light: #FFFFFF;
$lightgraycolor: #E8E8E8;
$lightergraycolor: #F7F7F7;

$boxShadow1: 0px 2px 2px rgba(0, 0, 0, 0.35);