@import "../../variable";

.footerBlock{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 50px 0 0 0;
    .inputGroup{

        .inputline{
            display: flex;
            gap: 20px;
            margin: 0 0 20px 0;
            @media screen and (max-width: 426px) {
                flex-direction: column;
                -webkit-grid-gap: 20px;
              }
    
            .footerInputText{  
                background-color: $themecolor4;
                border: 1px solid black;
                outline: none;
                padding: 10px 20px;
                font-size: 20px;
            }
        }
        .footerInputTextarea{
            background-color: $themecolor4;
            outline: none;
            font-size: 20px;
            padding: 10px 20px;
            width: 100%;
        }
    }
    .footerBtn{
        padding: 10px 160px;
        font-size: 20px;
        border: none;
        color: white;
        cursor: pointer;
        background-color: #502E1B;
        margin: 0 0 50px 0;
        &:hover{
            background-color: #8a5333;
        }
        @media screen and (max-width: 426px) {
            padding: 10px 100px;
          }
    }
    .footerContactCard{
        display: flex;
        // align-items: center;
        flex-direction: column;
        background-color: #EF8834;
        padding: 70px 150px;
        gap: 40px;
        @media screen and (max-width: 426px) {
            padding: 30px 20px;
            gap: 0;
          }
        .footerContactCardTopic{
            position: relative;
            @media screen and (max-width: 426px) {
                display: flex;
                align-items: flex-end;
            }
            .footerContactCardImg{
                position: absolute;
                width: 70px;
                left: -80px;
                @media screen and (max-width: 426px) {
                    position: static;
                  }
            }
        }
        .footerSideComponent{
            display: flex;
            @media screen and (max-width: 426px) {
                padding: 30px 20px;
                flex-direction: column;
            }
            .footerLeftSideContent{
                display: flex;
                flex-direction: column;
                gap: 20px;
                -webkit-grid-gap: 20px;
                .contactGroupText{
    
                }
            }
            .footerRightSideContent{
                display: flex;
                flex-direction: column;
                gap: 10px;
                -webkit-grid-gap: 10px;
    
            }
        }
    }
}