@import "../../../variable";

.notificationContainer{
    background-color: $lightgraycolor;
    width: 400px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 500px;
    overflow-y: scroll;
    border-radius: 10px;

    .notificationTopic{
        padding: 0px 10px;
    }
    .notificationContent{

        .notificationContentTopic{
            padding: 0px 10px;
            font-size: 20px;
        }
        .notificationSubcontent{
            display: flex;
            flex-direction: column;

            .notificationItem{
                display: flex;
                flex-direction: column;
                gap: 10px;
                padding: 10px;
                border-radius: 10px;
                background-color: $themecolor4;
                color: black;
                // height: 124px;
                text-overflow: ellipsis;

                .notificationItemTopic{

                }
                .notificationItemContent{
                    display: flex;
                    align-items: center;
                    gap: 10px;

                    .notificationItemPic{
                        border-radius: 100%;
                        height: 70px;
                        width: 70px;
                    }
                    .notificationDescription{
                        font-size: 13px;
                    }
                }
                .notificationTimeContainer{

                    .notificationTime{
                        float: right;
                    }
                }
            }
            .notificationItem2{
                @extend.notificationItem;
                background-color: $themecolor2;
            }
        }
    }
}