@import "../../variable";

$higherLabelColor: yellow;
// $headerBoxColor: $themecolor6;
// $leftLabelColor: 	#DB9691;
$leftLabelColor: $lightergraycolor;
// $boxColor: #FDF0D5;
$boxColor: white;
$weeklyLabelColor: #a5b3af;
$weeklyReportColor: #C6D8D3; 
$headerCompleteColor:  	$themecolor6;
$completeBoxColor: 	#C6D8D3;
$totalBoxColor:	#D1B7B2;
$tableGrid: 0 0 0 1px #331832;
$fontColor: #331832;

// 3 Divs of .leftLabelWrapper
$dropdownLabelWidth: 66px;
$scopeLabelWrapperWidth: 150px;
$sumHeaderWidth: 70px;

.themeMasterPlanPrimary{
    background-color: $themecolor6 !important;
}
.themeMasterPlanSecondary{
    background-color: $themecolor7 !important;
}
.themePlanPrimary{
    background-color: #F0E3A2 !important;
}
.themePlanSecondary{
    background-color: #F2C94C !important;
}
.themeInstalledPrimary{
    background-color: #B0DEFF !important;
}
.themeInstalledSecondary{
    background-color: #5EB6F5 !important;
}

.higherLabel {
    background-color: $higherLabelColor;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    
    overflow-wrap: break-word;
    word-wrap: break-word;
    box-shadow: $tableGrid;
    color: white;
    font-weight: 300;
    font-size: 13px;
}


.tableHighestWrapper{
    display: flex;
    flex-direction: column;
}
.tableHeaderBar{
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    // background-color: $themecolor6;
    padding: 5px 10px;
    font-weight: bold;
    // height: 20px;
    width: 100%;
    box-shadow: 0 0 0 1px #331832;
}
// Big Wrapper -> Header and ContentWrapper
.tableWrapper{
    display: flex;
    flex-direction: column;
    // Header
    .tableHeader{
        display: flex;

        .leftHeaders {
            display: flex;
            flex-shrink: 0;
            min-width: 120px;

            .dropdownHeader{
                flex-grow: 1;
                width: $dropdownLabelWidth;
                color: $fontColor;
            }
            .scopeHeader{
                width: $scopeLabelWrapperWidth;
                color: $fontColor;
            }
            .sumHeader{
                width: $sumHeaderWidth;
                color: $fontColor;
            }
            // display: grid;
            // grid-template-columns: 50px 150px 35px;
        }
        .rightHeaders {
            width: 100%;
            display: grid;
        }
    }
    // ContentWrapper -> Left Labels and Right Content
    .contentWrapper{
        display: flex;
        flex-direction: row;

        .leftLabels{

            display: flex;
            flex-direction: column;
        }

        .weeklyReportLabel{
            display: flex;
            // justify-content: center;
            // align-items: center; 
            flex-grow:0;
            height: 100%;
            // min-width: $dropdownLabelWidth;
            // background-color: $weeklyLabelColor;
            box-shadow: $tableGrid;
            font-size: 13px;
            // background-color: $themecolor6;
            .sumHeader{
                display: flex;
                flex-direction: column;
                justify-content: center;
                width: $dropdownLabelWidth;
                box-shadow: $tableGrid;
            }
            .planContainer{
                width: $scopeLabelWrapperWidth;
                display: grid;
                // flex-direction: column;
                grid-template-rows: 1fr 1fr;
                // align-items: center;
                box-shadow: $tableGrid;
                padding: 0 0 0 10px;
            }
            .planSumTotal{
                width: $sumHeaderWidth;
                display: flex;
                flex-direction: column;
                box-shadow: $tableGrid;
            }
            .sumValueTable{
                font-size: 16px;
                text-align: center;
            }
        }
    }
}

.leftLabels .leftLabelWrapper {
    display: flex;
    min-width: 120px;
    // background-color: $leftLabelColor;

    &:nth-child(odd){
        // background-color: #c0827e;
        // background-color: $themecolor7;
        background-color: $leftLabelColor !important;
    }

    .regionLabel {
        display: flex;
        flex-grow: 1;
        justify-content: center;
        align-items: center;
        text-align: center;
        flex-wrap: wrap;
        word-break: break-all;
        min-height: 30px;
        // flex: 0 0 0;
        width: $dropdownLabelWidth;
        // border: 1px solid magenta;
        box-shadow: $tableGrid;
        font-weight: 300;
        .regionText{
            height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
    .provinceLabelWrapper{

        // flex: 0 0 30px;
        // width: $scopeLabelWrapperWidth;
        // border: 1px solid magenta;
        display: grid;
        background-color: white;
        // grid-template-columns: 1fr 30px;
        grid-template-columns: $scopeLabelWrapperWidth $sumHeaderWidth;
        grid-auto-rows: 30px;
        // grid-gap:1px;
        // display:flex;
        // flex-direction: column;

        .provinceName{
            // text-align: center;
            overflow: hidden;
            text-overflow: ellipsis;
            box-shadow: $tableGrid;
            font-weight: 300;
        }
        .provinceQty{
            text-align: center;
            box-shadow: $tableGrid;
            font-weight: 300;
            // width: $sumHeaderWidth;
        }
    }
    .regionQty {
        display: flex;
        justify-content: center;
        align-items: center;
        // flex: 0 0 30px;
        width: $sumHeaderWidth;
        // border: 1px solid magenta;
        box-shadow: $tableGrid;

        // Center Text
        display: flex;
        justify-content: center;
        align-items: center;
        // text-align: center;
        // vertical-align: middle;
        font-weight: 300;
    }
}




.rightGridWrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 30px;
}

.box {
    background-color: $boxColor;
    // border: 3px solid red;
    box-shadow: $tableGrid;

    display: flex;
    justify-content: center;
    align-items: center;
    color: $fontColor;
    // font-size: 1.0rem;
    font-size: 13px;
    font-weight: 300;
    min-width: 0;
    border: none;
    &:focus{
        outline: none;
        
    }
    // If want row or column need to calculate in javascript!!
    // &:nth-child(even){
    //     background-color: #bb807c;
    // }
}

.planPerWeekBox{
    @extend .box;
    // background-color: $themecolor6;
    font-weight: bold;
}

.box.boxComplete{
    background-Color: $completeBoxColor;
    font-weight: 400;
}

.box.weeklyReportBox{
    background-color:  $weeklyReportColor;
    font-weight: 400;
}
.box.boxComplete.weeklyReportBox{
    background-color: $totalBoxColor;
    font-weight: 500;
}


.headerBox {
    // background-color: $headerBoxColor;
    box-shadow: $tableGrid;

    display: flex;
    justify-content: center;
    align-items: center;
}
.headerBox.headerBoxComplete{
    background-Color: $headerCompleteColor;
}
.headerBox.weekHeader{
    font-weight: 300;
    font-size: 0.8rem;
    // background-color: #E43955;
}

.dropDownInput{
    border: none;
    // background-color: $themecolor6;
    outline: none;
    font-weight: bold;
    height: 24px;
    min-width: 64px;
    width: 100%;
    // max-width: 100%;
}

