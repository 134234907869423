@import '../../variable';

.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: absolute;
  float: left;
  left: 50%;
  width: 451px;
  top: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  box-shadow: 0px 33px 74px rgba(0, 0, 0, 0.11), 0px 16.9254px 16.5289px rgba(0, 0, 0, 0.0655718),
    0px 5.87201px 4.92108px rgba(0, 0, 0, 0.0444282);
  border-radius: 16px;
  padding: 1em 2em;

  .selectInput {
    padding: 0.3em;
    font-family: KanitRegular;
    border: 0;
    font-size: 16px;
    border-radius: 4px;
    width: 100%;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .bottom {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
  }

  .wrapperBox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .header {
      align-self: flex-end;
    }
  }
  .wrapperButton {
    border: none;
    padding: 0.5em 1em;
    font-size: 14px;
    color: white;
    border-radius: 8px;
    background-color: #ff8206;
  }

  .wrapperButtonOutlined {
    @extend .wrapperButton;
    background-color: white;
    color: #ff8206;
    border: 1px solid #ff8206;
  }

  .boxInput {
    display: flex;
    flex-direction: column;
    gap: 10px;

    .warp {
      display: flex;
      align-items: center;
      gap: 8px;
      .icon {
        display: flex;
        flex-direction: column;
        justify-content: center;
        cursor: pointer;
      }
    }
  }
  // Text Line
  .panelcheckboxconfigtopic {
    font-family: 'Kanit';
    border: 0;
    border-bottom: 1px solid #b6b6b6;
    background-color: #fafafa;
    font-weight: 400;
    font-size: 16px;
    padding: 0.4em;
    width: 100%;
    ::placeholder {
      font-family: 'Kanit';
    }
    &:focus {
      background-color: #ffdab4;
    }
  }
}
