@import '../../variable';

.panelfirstline {
  padding: 0 0px 0 0px;
  position: relative;

  .panelform {
    @include flexAxisGap(column, 10px);

    width: 100%;
    background-color: #eeeeee;
    padding: 1.5em;
    border-radius: 16px;

    .patname {
      width: 100%;
      font-size: 32px;
      border: none;
      padding: 0 0.3em;
      background-color: white;
      border-bottom: 1px solid #b6b6b6;
      font-family: 'Kanit';
    }

    .selectForm {
      @extend .patname;
      font-size: 16px;
      padding: 0.3em;
    }

    .patdescription {
      @extend .patname;
      font-size: 22px;
    }

    .text {
      font-weight: bold;
    }

    .wrap {
      display: flex;
      gap: 16px;
    }
  }
}

.panelbtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #b6b6b6;
  border-radius: 16px;
  border: none;
  position: absolute;
  top: 0px;
  padding: 1em;
  right: -70px;

  cursor: pointer;
  // .panelIconBtn {
  //   width: 25px;
  // }
  // Hover doesn't work
  &:hover {
    background-color: #cc6805 !important;
  }
}

.wrapperButton {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #b6b6b6;
  border-radius: 16px;
  position: absolute;
  top: 0px;
  right: -75px;
  padding: 1em;
  cursor: pointer;
}

// If Focus within text, make panelbtn orange
.panelsubcontent:focus-within .panelbtn {
  background-color: $themecolor2;
}
.panelfirstline:focus-within .panelbtn {
  background-color: $themecolor2;
}

.panelcontent {
  position: relative;
  @include flexAxisGap(column, 10px);
  // margin: 20px 0 0 0;
  margin-top: 20px;
  padding: 0 0px 0 0px;

  .panelsubcontent {
    display: flex;
    padding: 1.5em;
    position: relative;
    background-color: #eeeeee;
    flex-direction: column;
    border-radius: 16px;

    .panelitems {
      display: flex;
      flex-direction: column;
      gap: 10px;

      .wrapperButtonImg {
        border: 0;
        background-color: white;
        font-family: 'Kanit';
        display: flex;
        gap: 16px;
        align-items: center;
        padding: 0.5em;
        border-radius: 4px;
        max-width: 200px;
        width: 100%;
        cursor: pointer;

        &[aria-checked='true'] {
          pointer-events: none;
          opacity: 0.6;
        }
      }

      .footerCard {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      .wrapperButtonFooter {
        @extend .wrapperButtonImg;
        justify-content: space-between;
        font-size: 16px;
      }
      // Between right and left (lineItem & boxes)
      .panelsubitems {
        display: flex;
        gap: 20px;
        align-items: flex-start;

        @media screen and (max-width: 769px) {
          flex-direction: column-reverse;
          gap: 8px;
          border-radius: 10px;
          padding: 0.5em;
          background-color: #d8d8d8;
        }

        //
        .panelsubcontentgroupbtn {
          display: flex;
          justify-content: center;
          // background-color: $themecolor2;
          background-color: $themecolor3;
          border-radius: 10px;
          padding: 0 5px;
          // gap: 2px;
          height: 30px;

          .panelsubcontentbtn {
            background-color: transparent;
            display: flex;
            justify-content: center;
            border: 0;
            // margin: 0 10px;
            padding: 8px;
            border-radius: 16px;

            &:hover {
              cursor: pointer;
              background-color: white;
            }
          }
        }
      }

      // Wrapper of the whole "UPLOAD" Line Type
      .uploadLineTypeWrapper {
        // background-color: rgba(131, 131, 131, 0.1);
        // background: white;
        background: #f1f1f1;
        padding: 12px;
        flex-basis: 80%;
        display: flex;
        flex-direction: column;
        border-radius: 5px;
        gap: 5px;

        &:focus-within {
          background: #ffdab4;
        }

        // Text Line
        .panelcheckboxconfigtopic {
          font-family: 'Kanit';
          border: 0;
          border-bottom: 1px solid black;
          // background-color: #FFFFFF;
          background-color: transparent;
          font-weight: 400;
          font-size: 15px;
          // flex-basis: 80%;
          width: 100%;
          ::placeholder {
            font-family: 'Kanit';
          }
          &:focus {
            background-color: #ffdab4;
          }
        }

        // Before and After Checkbox Wrapper
        .beforeAfterWrapper {
          display: flex;
          gap: 10px;
        }

        // File Configuration Wrapper
        .fileConfigWrapper {
          display: flex;
          // justify-content: space-between;

          .fileConfigLeftWrapper {
            display: flex;
            flex: 2 0 auto;
            flex-direction: column;
            gap: 10px;
          }

          .fileConfigRightWrapper {
            display: flex;
            flex-direction: column;
            flex: 3 0 auto;
            .radioGridWrapper {
              display: grid;
              grid-template-columns: 120px 1fr;
              position: relative;

              .uomTextField {
                position: absolute;
                right: 0px;
                bottom: 0px;
              }
            }
          }
        }
      }

      .panelacceptancebox {
        // @extend .uploadLineTypeWrapper;

        // flex-direction: row;
        display: flex;
        gap: 22px;
        background-color: white;
        width: 100%;
        padding: 0.5em 1em;
        border-bottom: 1px solid #b6b6b6;

        .groupRadio {
          display: flex;
          gap: 8px;
          align-items: center;
        }
      }

      .panelsubitems2 {
        background-color: rgba(131, 131, 131, 0.1);
      }

      .panelsubcontenttextfield {
        font-family: 'Kanit';
        border: 0;
        border-bottom: 1px solid #b6b6b6;
        background-color: white;
        font-weight: 400;
        font-size: 15px;
        width: 100%;
        padding: 0 0.5em;
        ::placeholder {
          font-family: 'Kanit';
        }
        &:focus {
          background-color: #ffdab4;
        }

        &.headerTextField {
          font-size: 32px;
          font-weight: 700;
        }

        &.h2TextField {
          font-size: 24px;
          font-weight: 700;
        }
      }

      .panelsubcontentfilefield {
        @extend .panelsubcontenttextfield;
        padding: 10px 0;
        border-bottom: 0;
        display: flex;
        justify-content: space-evenly;
      }
    }

    .panelsubcontentfooter {
      display: flex;
      align-items: center;
      gap: 20px;
      justify-content: flex-end;

      .panelsubcontentfooterbtn {
        cursor: pointer;
      }
    }
  }
}

// If Text Field Highlighted, make subcontentgroup orange!
.panelsubitems:focus-within .panelsubcontentgroupbtn {
  background-color: $themecolor2 !important;
}

.upButton {
  position: fixed;
  bottom: 30px;
  right: 30px;
  border: none;
  width: 40px;
  height: 40px;
  background-color: $themecolor2;
  scroll-behavior: smooth;
  font-size: 15px;
  padding-top: 5px;
  color: white;
  cursor: pointer;
}

.lineIndexLabel {
  position: absolute;
  left: -45px;
  top: 10px;
  width: 48px;
  height: 48px;
  background-color: $themecolor2;
  z-index: -1;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 25px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  color: white;
  font-weight: bold;
}

.imageBorder {
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 267px; // 4:3 aspect ratio

  .imageInside {
    width: 200px;
    height: 267px;
    object-fit: contain;
  }
}
