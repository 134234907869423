@import "/src/variable";

.reportNCContainer{
    display: flex;
    flex-direction: column;
    gap: 20px;
    .exportBtnContainer{
        display: flex;
        justify-content: flex-end;
    }
    .graphCardContainer{
        display: grid;
        grid-template-areas:
          'graph1 graph2'
          'graph3 graph4';
        grid-template-rows: 1fr 1fr;
        grid-template-columns: 1fr 1fr;
        gap: 20px;
        width: 100%;
        // height: 420px;

        .graph{
            background-color: white;
            padding: 20px;
        }
        .graph1{
            @extend .graph;
            grid-area: graph1;
        }
        .graph2{
            @extend .graph;
            grid-area: graph2;

        }
        .graph3{
            @extend .graph;
            grid-area: graph3;

        }
        .graph4{
            @extend .graph;
            grid-area: graph4;

        }
        .graphTopic{
            text-align: center;
        }
    }
    .tableSectionContainer{
        
        .tableWorkTypeContainer{
            display: flex;
            flex-direction: column;
            gap: 20px;
        }
    }
}

.refreshButton{
    color: #ffffff;
    // background-color: #f8ea25;
    background-color: #006E7F;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    font-weight: 700;


    display: flex;
    align-items: center;

    margin-right: 0.5em;

}


.loader {
    display:inline-block;
    margin-right: 0.5em;

    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;    
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}