@import '../../variable';

.subCard {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 1em;
  background-color: white;
  flex-direction: column;

  .wrapperContainerButton {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .wrapperButton {
    display: flex;
    flex-direction: column;
    width: 20%;
    align-items: center;
    align-self: flex-end;
  }
  .wrap {
    .textErr {
      color: red;
      text-align: right;
      font-size: 14px;
    }
  }
  .wrapperInput {
    display: grid;
    grid-template-columns: 100px auto;
    gap: 8px;
    align-items: center;
  }

  .wrapperTable {
    padding: 1em 2em;
    border: 1px solid #ececec;

    .box {
      display: flex;
      flex-direction: column;
      gap: 16px;
      .wrapperInput {
        display: flex;
        gap: 16px;
        align-items: center;
        .boxInput {
          width: 100%;
          &[aria-checked='true'] {
            border: 1px solid red;
          }
        }
        .text {
          max-width: 100px;
          width: 100%;
        }
      }

      .footerBox {
        display: flex;
        width: 100%;
        .text {
          max-width: 100px;
          width: 100%;
        }
      }
    }
  }

  .bottomBox {
    .groupInput {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-row-gap: 10px;
      grid-column-gap: 10px;

      .wrapBox {
        display: grid;
        align-items: center;
        grid-template-columns: 200px 1fr;

        .title {
          font-size: 14px;
        }
      }
    }
    .inputDisbles {
      padding: 0.4em;
      border-radius: 4px;
      background-color: #ececec;
      width: 100%;
      &[aria-checked='true'] {
        border: 1px solid #ff3d3d;
      }
      &[data-visible='false'] {
        border: 1px solid #27ae60;
      }
    }

    .inputDisblesBT {
      padding: 0.4em;
      border-radius: 4px;
      color: white;
      background-color: #ff3d3d;
      width: 100%;
      &[aria-checked='true'] {
        background-color: #27ae60;
      }
    }
  }
}
