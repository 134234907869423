@import "../../variable";

.homeContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin: 50px auto auto auto;

    .homeTopic{

    }
    .homeContent{
        display: flex;
        flex-direction: column;
        gap: 20px;
        background-color: $themecolor2;
        padding: 40px;
        width: 100%;

        .homeContentTopic{
            font-size: 32px;
        }
        .homeItem{
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin: 0 40px;

            .homeSubItem{

                .homeSubItemTopicGroup{
                    display: flex;
                    position: relative;
                    align-items: center;
                    cursor: pointer;
                    width: fit-content;

                    .plusImg{
                        position: absolute;
                        left: -30px;
                    }
                    .homeSubItemTopic{
    
                    }
                }
                .homeSubItemContent{

                    
                }
                .homeSubItemNumberContent{
                    margin: 0 0 0 40px;
                    position: relative;

                    .homeSubItemNumberItem{
                        position: absolute;
                        left: -20px;
                    }
                }
            }
        }
    }
}