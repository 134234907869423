@import '../../variable';

.toolbarWrapper {
  display: flex;
  justify-content: space-between;

  .lefttool {
    display: flex;
    gap: 20px;

    .thirdcontentselect {
      font-size: 15px;
      font-family: 'Kanit';
      padding: 0 10px;
      font-weight: bold;
    }
  }
}

.arrowParentWrapper {
  position: relative;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  margin-left: 5px;

  // background-color: lawngreen;
}

.btnnobackground {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  background-color: transparent;
  border: none;
  cursor: pointer;
  .btnnobackgroundimg {
    width: 30px;
    height: 25px;
  }
}

.righttool {
  display: flex;
  height: 30px;

  .exportBtn {
    cursor: pointer;
    display: flex;
    align-items: center;
    background-color: #ff8206;
    border: none;
    border-radius: 10px;
    padding: 10px;
    position: relative;
    .TextInBtn {
      font-weight: 300;
      color: #ffff;
    }
    .exportZone {
      user-select: none;
      width: 10rem;
      background-color: #ffff;
      border-radius: 5px;
      overflow: hidden;
      position: absolute;
      bottom: -3rem;
      left: 0px;
      .exportItem {
        padding: 2px;
        color: #626262;
        font-size: 13px;
        &:hover {
          background-color: #ededed;
        }
      }
    }
  }
}
.columnCheckboxWrapper {
  // display:none;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  // justify-content: space-evenly;
  gap: 3px;
  font-family: $defaultfont;

  position: absolute;
  left: -50px;
  top: 30px;
  padding: 10px 5px;
  z-index: 1;
  // opacity: 0;

  width: 150px;
  background-color: #fef5e5;
}

.hiddenColumnToggle:checked + div {
  display: none;
}
