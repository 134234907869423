.tableHighestWrapper{
    // border: 1px solid red;
    background-color: #F2994A;
    border-radius: 5px 5px 0px 0px;
    padding-top: 20px;
    // border: 0.5px solid grey;
    .TableReportStatus{
    border-collapse: collapse;
    width: 100%;
    th{
        padding-top: 12px;
        padding-bottom: 12px;
        text-align: center;
        background-color: #F2994A;
        border: 0.5px solid grey;
        // border-radius: 5px 5px 0px 0px;
        border-collapse: collapse;

        
    } 
    td {
        border: 0.5px solid grey;
        padding: 15px; 
        text-align: center;
        background-color:  #FFF;
             
    }
    .total{
        background-color: #FFCDA4;
    }
    .HeadTable{
        // border: 1px solid red;
        display: flex;
        background-color: #FFF;
        font-size: 18px;
        font-weight: bold;
        flex-shrink: 0;
        text-align: center;

        .borderHead{
            border: 1px solid gray;
            width: 100%;
        }
    }
    
    }
     
}