
.antModalContainer {
  display: flex;
	flex-direction: column;
	gap: 0.5rem;
	
	position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  // border: 2px solid black;
	border: 1px solid var(--gray-day-gray-2, #ECECEC);
	border-radius: 1rem;
	box-shadow: 0px 4px 20px 0px rgba(9, 44, 76, 0.16);
	padding: 1rem;
}
.colorWrapper{
	display: flex;
	gap: 0.5rem;
}
.colorGroupWrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.colorGrab {
	width: 3rem;
	height: 3rem;
	border-radius: 0.25rem;
	cursor: grab;
}


// --------- For Color Group Modal --------
.cgModalContainer {
  display: flex;
	flex-direction: column;
	gap: 0.5rem;
	
	position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: white;
  // border: 2px solid black;
	border: 1px solid var(--gray-day-gray-2, #ECECEC);
	border-radius: 1rem;
	box-shadow: 0px 4px 20px 0px rgba(9, 44, 76, 0.16);
	padding: 1rem;
	
}

.colorBox {
	width: 3.3rem;
	height: 3.3rem;
	border-radius: 0.25rem;
	cursor: pointer;
}
.colorPickerModal {
	display: none;
	position: absolute;
	top: 3rem;
	left: 3rem;
	// height: 100%;
	background-color: white;
	padding: 0.5rem;
	border-radius: 0.75rem;
	box-shadow: 0px 4px 32px 0px rgba(35, 23, 5, 0.26);
	&.active {
		display: flex;
		flex-direction: column;
		// justify-content: flex-end;
		align-items: flex-end;
		z-index: 99;
	}

	button {
		border: none;
		background-color: transparent;
		cursor: pointer;
		font-size: 1.25rem;
	}
}
.deleteButton {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.1rem;
    cursor: pointer;
    font-family: Kanit;
    font-size: 0.9rem;
	color: red;
}
.saveButton{
	display: flex;
	height: 2.5rem;
	padding: 0px 1rem;
	justify-content: center;
	align-items: center;
	border-radius: 10px;
	border: none;
	color: white;
	cursor: pointer;
	font-size: 1rem;
	font-family: Kanit;
	background-color: var(--primary-main, #FF8206);

	&:hover{
		filter: brightness(0.95);
	}
}

.inputWrapper{
	display: flex;
	flex-direction: column;
	gap: 0.5rem;

	.label{
		display: grid;
		grid-template-columns: 2.5rem 1fr;
	}

	.inputText{
		padding: 0.5rem;
		border: none;
		border-bottom: 1px solid var(--border, #B6B6B6);
		background: var(--gray-1, #FAFAFA);
	}
}