@import "../../variable";

.loginpageContainer{
    display: flex;
    padding: 20px;
    justify-content: center;

    @media screen and (max-width: 426px) {
        flex-direction: column;
        // padding: 200px 0;
    }
}