@import "../../variable";


.tableContainer{
    display: flex;
    flex-direction: column;
    font-weight: 500;
    padding: 5px 2px;
    border-radius: 5px;
    background-color: #FFCDA4;
    width: 350px;
    // overflow: scroll;

    .tableTopic{
        padding: 5px 10px;
    }
    .tableHeader{
        display: grid;
        grid-template-columns: 64px 10fr 10fr 10fr 10fr;
        grid-auto-flow: column;
        gap: 5px;
        align-items: flex-end;
        .tableRowColumn{
            background-color: white;
        }
    }
    .tableRow{
        display: grid;
        // grid-template-columns: 64px 10fr;
        grid-template-columns: 64px 10fr 10fr 10fr 10fr;
        gap: 5px;
    }
    .tableFooter{
        display: grid;
        // grid-template-columns: 64px 10fr;
        grid-template-columns: 64px 10fr 10fr 10fr 10fr;
        gap: 5px;
        .tableRowColumnFooter{
            text-align: center;
        }
    }
}

.tableRowTopic{
    text-align: center;
    
}

.tableRowColumn{
    background-color: transparent;
    border: none;
    text-align: center;
    font-weight: bold;
    width: 100%;
    &:focus{
        outline: 1px solid black;
    }
    &:disabled{
        background-color: $lightgraycolor;
    }
}

.dropDownInput{
    border: none;
    background-color: white;
    outline: none;
    font-weight: bold;
    height: 24px;
    min-width: 64px;
    width: 100%;
    // max-width: 100%;
}