@import "/src/variable";

.tableContainer{
    display: flex;
    flex-direction: column;
    // padding: 5px 2px;
    border-radius: 5px;
    background-color: #FFCDA4;
    width: auto;
    // overflow: scroll;
    width: 500px;
    padding: 0 10px;

    .tableTopic{
        padding: 5px 10px;
    }
    .tableHeader{
        // border: 1px solid red;
        display: grid;
        grid-template-columns: 0.2fr repeat(3, 1fr);
        grid-auto-flow: column;
        gap: 5px;
        // align-items: flex-end;
        font-weight: bold;
        justify-content: center;
        .tableRowColumn{
            height: 30px;
            background-color: #FFCDA4;
            overflow: hidden;
        }
    }
    .tableContent{
        max-height: 400px;
        overflow-y: scroll;
        overflow-x: hidden;
        &::-webkit-scrollbar {
            // display: none;
            width: 5px;
            // background: red; 
            border-radius: 5px;
        }
        &::-webkit-scrollbar-thumb {
            background: white; 
            border-radius: 50px;
            padding: 5px;
        }
    }
    .tableRow{
        display: grid;
        grid-template-columns: 0.2fr repeat(3, 1fr);
        gap: 5px;
        height: 21px;
        .tableRowColumn{
            width: 145px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: fit-content;
        }
    }
    .tableFooter{
        display: grid;
        // grid-template-columns: 64px 10fr;
        grid-template-columns: 1fr 1fr;
        gap: 5px;
        height: 30px;
        .tableRowLeftSide{
            text-align: left;
        }
        .tableRowRightSide{
            text-align: right;
            padding: 0 5px 0 0;
        }
    }
}

.tableRowTopic{

    text-align: center;
    
}

.tableRowColumn{
    background-color: transparent;
    border: none;
    text-align: center;
    // font-weight: bold;
    width: 100%;
    // width: 200px;
    // white-space: nowrap;
    // overflow: hidden;
    // text-overflow: ellipsis;
  
}

