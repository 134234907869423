/* colors */
$blue: #293b49;
$pink: #fdabaf;
$pink-light: #ffe0e6;
$green: #04cba0;
$green-dark: #01ac88;
$white: white;
$orange: #f2994a;
$textgray: #4f4f4f;
$yellow: #f2c94c;
// html,
// body {
//   height: 100%;
//   margin: 0;
// }

// html {
//   font-size: 62.5%;
// }

body {
  font-family: 'Kanit';
}

.center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $blue;
  gap: 20px;

  .cardArea {
    // border: 1px solid red;
    // width: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    // margin: 20px;
    // border: 1px solid green;
    .cardNews {
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      position: relative;
      width: 900px;
      height: auto;
      background: #f2f2f2;
      border-radius: 20px;
      .header {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 20px;
        .headZone {
          display: flex;
          justify-content: space-between;
          align-items: baseline;
          .titleText{
            .headtext {
              font-size: 30px;
              font-weight: 700;
            }
            .posttext{
              font-size: 25px;
              font-weight: 500;
              color: #313131;
            }
          }
          
          .countUser {
            display: flex;
            align-items: baseline;
            gap: 10px;
            .countItem {
              display: flex;
              font-size: 24px;
            }
            .countItem2 {
              display: flex;
              width: 20px;
              height: 20px;
            }
          }
        }

        .detail {
          display: flex;
          justify-content: space-between;
          .file {
            display: flex;
            flex-direction: column;
            gap: 3px;
            width: 100%;

            .filebox {
              display: flex;
              gap: 5px;
              align-items: center;
            }
            .filetext {
              font-size: 14px;
              width: 200px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              // word-wrap: break-word;
              cursor: pointer;
            }
            .logoFile {
              width: 15px;
              height: 15px;
            }
          }

          .companytext {
            font-size: 20px;
            font-weight: 400;
          }
          .rigthead {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 100%;
            text-align: right;
 
          }
          .subtext {
            .titletext {
              font-weight: bold;
              padding-right: 10px;
            }
          }

          .companyName {
            font-size: 20px;
            width: 100%;
          }
        }
      }

      .line {
        border-top: 2px solid $white;
      }

      .twosides {
        margin: 20px;
        display: flex;
        .descript {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 100%;

          .textdes {
            color: $textgray;
          }

          .workbox {
            // border: 1px solid red;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .textwork {
              display: flex;
              justify-content: space-between;

              .worktext {
                padding-left: 5px;
              }
            }
            .textsub {
              display: flex;
            }
          }
          .detail {
            display: flex;
            justify-content: space-between;
            .file {
              display: flex;
              flex-direction: column;
              gap: 3px;
              width: 100%;
              .filebox {
                display: flex;
                gap: 5px;
                align-items: center;
              }
              .filetext {
                font-size: 14px;
                width: 200px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;
                // word-wrap: break-word;
              }
              .logoFile {
                width: 15px;
                height: 15px;
              }
            }

            .companytext {
              font-size: 20px;
              font-weight: 400;
            }
            .rigthead {
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              width: 100%;
              text-align: right;

              .subtext {
                .titletext {
                  font-weight: bold;
                  padding-right: 10px;
                }
              }
            }

            .companyName {
              font-size: 20px;
              width: 100%;
            }
          }
        }
        .imageside {
          display: flex;
          flex-direction: column;
          align-items: center;
          width: 100%;
          text-align: center;
          // border: 1px solid red;
          .imgshow {
          }
          .imageshow {
            width: 300px;
            height: 221px;
          }

          .textdate {
            text-align: right;
            font-size: 14px;
            // padding-top: 50px;
          }
        }
      }
      .bottomtext {
        display: flex;
        justify-content: space-between;
        margin: 0 20px 10px 20px;
        // padding-left: 10px;
        // padding-right: 10px;
        .tRegion {
          display: flex;
          gap: 10px;
          .tRegiontext {
          }
          .headtext {
            font-weight: bold;
          }
        }
      }
      .bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $orange;
        border-radius: 0px 0px 20px 20px;
        height: 60px;
        margin-top: auto;
        .btnform {
          .applyBt {
            // border: 1px solid red;
            border: none;
            width: 350px;
            height: 40px;
            background: $yellow;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            font-family: 'Kanit';
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }

      .jobboxArea {
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 20px;

        .jobbox {
          background-color: $white;
          border-radius: 20px;
          height: auto;

          .texthead {
            padding: 20px;
            font-size: 20px;
            font-weight: bold;
          }

          .boxtext {
            margin: 20px;
            display: flex;
            gap: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .center {
    .filterbox {
      // background-color: #04cba0;
      margin: 20px;
      font-size: 14px;
    }
    .cardArea {
      .cardNews {
        // border: 1px solid red;
        // height: 100vh;
        width: 650px;
        height: auto;
        font-size: 14px;
      }
    }
  }
}
