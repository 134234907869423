@import "../../variable";

.indexBlock{
    @media screen and (max-width: 769px) {
        overflow: hidden;
     }

    .firstIndexBox{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .frontGround{
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            position: absolute;
        }
        .firstIndexImg{
            width: 100%;
             @media screen and (max-width: 426px) {
                object-position: -450px -179px;
                object-fit: none;
             }
        }
        .firstIndexContent{
            position: absolute;
            text-align: center;

            .firstIndexText{
                color: white;
                width: 80%;
                font-size: 30px;
                margin: auto auto 20px auto;
                @media screen and (max-width: 426px) {
                    font-size: 20px;
                 }
            }
        }
    }
    .secondIndexBox{
        display: flex;
        flex-direction: column;
        gap: 50px;
        padding: 20px 0 50px 0;
        @media screen and (max-width: 426px) {
            gap: 20px;
          }
        .secondIndexBoxTopicBox{
            background-color: $themecolor2;
            width: fit-content;
            padding: 10px 150px 10px 50px;
            @media screen and (max-width: 426px) {
               padding: 10px 50px 10px 50px;
               
             }
            .secondIndexBoxTopic{
                font-size: 30px;
            }
        }
        .secondIndexBoxContent{
            display: flex;
            gap: 50px;
            justify-content: center;
            align-items: center;
            @media screen and (max-width: 426px) {
                flex-direction: column;
                
              }
            .secondIndexBoxLeftSideContent{
                display: flex;
                flex-basis: 60%;
                justify-content: center;

                .secondIndexBoxLeftSideItems{
                    background-color: #E8E8E8;
                    text-align: center;
                    border: 1px solid black;
                    padding: 20px;
                    width: 150px;
                    height: 150px;
                    cursor: pointer;
                    @media screen and (max-width: 426px) {
                        width: 130px;
                        height: 130px;
                      }
                    @media screen and (max-width: 321px) {
                        padding: 10px;
                        width: 105px;
                        height: 105px;
                      }

                    &.secondIndexBoxLeftSideItemsSelected{
                        background-color: $themecolor2;
                    }
                    .leftSideImg{
                        width: 70px;
                        @media screen and (max-width: 426px) {
                            width: 55px;
                          }
                        @media screen and (max-width: 321px) {
                            width: 35px;
                          }
                    }
                    .leftSidetext{

                    }
                }
            }
            .secondIndexBoxRightSideContent{
                flex-basis: 40%;
                text-align: center;
                .rightSideImg{
                    width: 160px;
                    @media screen and (max-width: 426px) {
                        width: 100px;
                      }
                }
                .rightSidetopic{
                    font-size: 30px;
                }
                .rightSideText{
                    font-size: 20px;
                }
            }
        }
    }
    .thirdIndexBox{
        padding: 30px 70px 300px 50px;
        background-color: $themecolor2;
        position: relative;
        @media screen and (max-width: 426px) {
            padding:  30px 30px 300px 0;
            overflow: hidden;
          }
        .thirdIndexContent{
            background-color: white;
            display: flex;
            flex-direction: column;
            width: fit-content;
            text-align: center;
            align-items: center;
            padding: 20px;
            gap: 65px;
            position: relative;
            @media screen and (max-width: 426px) {
                padding: 45px 20px;
            }

            .thirdIndexTopicGroup{

                .thirdIndexTopic{
                    
                }
                .thirdIndexSubTopic{

                }
            }
            .thirdIndexItems{
                padding: 0 150px 0 0;
                @media screen and (max-width: 426px) {
                    padding: 0;
                  }
                .ItemsImg{
                    width: 80px;

                }
                .Itemstext{

                }
            }
            .weldingpic{
                width: 431px;
                position: absolute;
                top: 70px;
                left: 330px;
                @media screen and (max-width: 426px) {
                    top: 685px;
                    left: 73px;
                  }
            }
            .registerBtn{
                width: fit-content;
                color: black;
                @media screen and (max-width: 426px) {
                    margin: 580px 0 0 0;
                  }
            }
        }
        .engineerpic{
            position: absolute;
            left: 415px;
            top: 661px;
            width: 399px;
            @media screen and (max-width: 426px) {
                left: 130px;
                top: 1380px;
            }
            @media screen and (max-width: 321px) {
                top: 1414px;
            }
        }
    }
    .fourthIndexBox{
        background-color: $themecolor4;
        display: flex;
        gap: 50px;
        padding: 30px 60px;
        align-items: center;
        @media screen and (max-width: 426px) {
            text-align: center;
            flex-direction: column;
            padding: 30px 20px;
         }
        .fourthIndexLeftSideContent{
            flex-basis: 40%;
            text-align: center;
        }
        .fourthIndexRightSideContent{
            display: flex;
            flex-direction: column;
            gap: 20px;
            flex-basis: 60%;
            @media screen and (max-width: 426px) {
                gap: 20px;
                text-align: left;
             }
            .fourthIndexRightSideItems{
                display: flex;
                gap: 20px;
                align-items: center;

                .fourthIndexIndex{
                    padding: 16px 20px;
                    border-radius: 100%;
                    background-color: $themecolor2;
                    font-weight: bold;

                }
            }
        }
    }
    .fifthIndexBox{
        display: flex;
        flex-direction: column;
        text-align: center;
        background-color: $themecolor2;
        padding: 30px 20px;

        .fifthIndexTopic{

        }
        .fifthIndexSubTopic{

        }
    }
}

.inputBtn{
    cursor: pointer;
    padding: 10px 20px;
    border: none;
    background-color: #EF8834;
    font-size: 20px;
}
