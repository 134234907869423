@import "../../variable";

.overflowContainer{
    overflow-x: scroll;
    overflow-y: hidden;
}

.gridContainer{
    // background-color: black;
    // border-radius: 10px;
    // width: 100%;
    // display: flex;
    
    
    // display: flex;
    // padding-left: 100px;
    // transform: translateX(100px);
    
}

.tableWrapper {
    // margin: 0px 0 0 0;
    // padding: 0px 0px 0px 100px;
    // width: 1000px;
    display: grid;
    // border-spacing: 0px;
    // border-collapse: collapse;
    // border-collapse: separate;
    // width: auto;
    // flex: 1;
    // box-sizing: border-box;
    // grid-gap: 1px;
    // grid-template-rows: 60px;

    // These elements don't produce a specific box by themselves. They are replaced by their pseudo-box and their child boxes.
    // Somehow this breaks the border collapse: collapse?!?
    thead,
    tbody,
    tfoot,
    tr {
        display: contents;
    }

    // All Data cells
    // Make Overflows hidden, all overflows noted with ..., and just make text in one line with nowrap
    th, td {
        // box-shadow:0 0 0 1px black;
        margin: -1px -1px 0 0;
        // height: 55px;
        border: solid 1px #000;
        background-color: white;
        // border-radius: 10px;
        // border-right: solid 0.5px black;
        // border-left: solid 0.5px black;
        // box-shadow:0 0 0 1px black;
        // border-right: black solid 3px;
        // border-left: black solid 3px;
        // width: 50px;
        // padding: 15px;
        // overflow-x: hidden;
        // text-overflow: ellipsis;
        // text-overflow: wrap;
        // overflow-wrap: break-word;
        // word-wrap: break-word;
        // white-space: nowrap;
    }

    // All  Head Cells
    // Make Background and white font
    th {
        background: #FF8206;
        // text-align: left;
        font-weight: 500;
        font-size: 1rem;
        font-size: 0.8rem;
        // color: white;
        // position: relative;
        overflow-x: hidden;
        white-space: nowrap;
      }

    // All Data Cells
    // Make some top bottom padding and gray font
    td {
        
        display: flex;
        align-items: center;
        // padding-top: 2px;
        // padding-bottom: 2px;
        padding: 0px 0px;
        // color: #808080;
        color: black;
        font-family: $defaultfont;
        font-size: 15px;
        font-weight: 300;
        
        // overflow-x: hidden;
    }

    // Nice colors at even childs
    tr:nth-child(even) td {
        background: rgb(255,214,173);
    }

    .tdtextfield{
        cursor: cell;
        outline: none;
        border: none;
        width: 100%;
        height: 100%;
        padding: 0px 0px;
        // margin: 10px 0px;
        margin: 0px;
        background-color: transparent;
        &:focus {
            background-color: #fff8a0;
        }
    }
    .tdDropdownField{
        width: 100%;
        height: 100%;
        margin: 0px;
        padding: 0px;
    }
    .dateTextField{
        height: 32px;
    }
    .tdReadOnly {
        overflow-x: hidden;
        overflow-y: hidden;
        width:100%;
        text-overflow: ellipsis;
        white-space: nowrap;
        // height:100%;
        // overflow-wrap: normal;
    }
    // .tooltip {
    //     position: relative;
    //     display: inline-block;
    //     // border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
    //   }
    // /* Tooltip text */
    // .tooltip .tooltiptext {
    //     visibility: hidden;
    //     width: 120px;
    //     background-color: black;
    //     color: #fff;
    //     text-align: center;
    //     padding: 5px 0;
    //     border-radius: 6px;
    
    //     /* Position the tooltip text - see examples below! */
    //     position: absolute;
    //     z-index: 1;
    // }
    // /* Show the tooltip text when you mouse over the tooltip container */
    // .tooltip:hover .tooltiptext {
    //     visibility: visible;
    // }
}

.buttonnoneIssue{
    font-family: 'Kanit';
    background-color: red;
    color: #FFF;
    border: 1px solid #888;
    width: 100%;
    margin: 0 auto;
    align-self: center;
    justify-self: center;
    cursor: pointer;
}

.buttonIssue{
    @extend .buttonnoneIssue;
    background-color: #FFF;
    color: #000;
    font-family: 'Kanit';
   
}
.removeButton{
    border: none;
    background-color: transparent;
    cursor: pointer;
    display: flex;
    padding: 0px;

    svg{
        width: 18px;
        height: 18px;
        fill: #E84A4A;
        &:hover{
            fill: #b94040;
        }
    }
}


.paginationWrapper {
    display: flex;
    // gap: 5px;
    justify-content: flex-end;
    flex-wrap: wrap;

    .paginationButton{
        background-color: #FFF;
        border: 1px solid #888;
        // padding: 5px 10px;
        padding: 0px;
        width: 30px;
        height: 30px;
        cursor: pointer;

        &.paginationSelected{
            background-color: $themecolor2;
        }
    }
}

.patLinkWrapper{
    // margin: 0 auto;
    // margin-left: 10px;
    display: contents;
    // width: 100%;
    
    .patLinkButton{
        width: 100%;
        margin: 0 auto;
        border: 1px solid gray;
        align-self: center;
        justify-self: center;
        background-color: white;
        cursor: pointer;
        // width: 120px;
        &:hover{
            background-color: #DDD;
        }
    }
}

.sortButton{
    width: 15px;
    height: 15px;
    // viewBox: ""
    fill: #CCC;
    cursor: pointer;
    &:hover{
        fill: #000;
    }
    &.active{
        fill: #0683FF;
    }
}

//modal
.modal {
    font-family: 'Kanit';
   
    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      background: #FFFFFF;;
      border-radius: 10px;
      width: 900px;
      height: 400px;
       .inputfiled {
        width: 100%;
        height: 37px;
        background-color: transparent;
        border: 1px solid #828282;
        border-radius: 5px;
        box-sizing: border-box;
        font-family: 'Kanit';
      }

      .Area {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 20px;
        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 10px;
          .textHead {
            font-size: 27px;
            font-weight: bold;
            text-align: center;
          }
        }

        .groupInput{
          display: flex;
          flex-direction: column;
          gap:30px;
          padding: 20px;

          .IssueInput {
            font-size: 15px ;
            font-weight: 400;

            .inputIssue{
              @extend .inputfiled;
              height: 100px;
            }
          }
        }
      }

      .buttonform{
        margin: 20px;
      }

      .btnFooterWrapper{
        font-family: 'Kanit';
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    
    .btnCancle{
        font-size: 1rem;
        width: 240px;
        font-family: 'Kanit';
        height: 40px;
        background-color: #BDBDBD;
        border: none;
        border-radius: 5px;
        color:#ffffff;
        cursor: pointer;
    }
    
    .btnNext{
        @extend .btnCancle;
        background-color: #F2C94C;
        color:black;
    }
    
    .btnConfirm{
        @extend .btnCancle;
        background: #F2994A;
        color:black;  
    }
    //* Text
    .textTitle{
        font-size: 1.25rem;
    }
    }
  }