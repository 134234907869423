.center {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // color: $blue;
    padding-top: 20px;
    gap: 20px;
  
  


.container{
    width: 900px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    padding: 30px;
}
    .flexbox{
        display: flex;

    }
    .inputBox{
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 15px;


        .inputGroup{
            display: flex;
            flex-direction: column;
            gap: 5px;
        .inputfiled {
                // width: 540px;
                height: 37px;
                background-color: transparent;
                border: 1px solid #828282;
                border-radius: 5px;
                box-sizing: border-box;
                font-family: 'Kanit';
              }
        }
        .inputfile{
            width: 0.1px;
              height: 0.1px;
              opacity: 0;
              overflow: hidden;
              position: absolute;
              z-index: -1;
          }
            
          .labelfile{
            // border: 1px solid red;
            font-size: 15px;
            font-weight: bold;
            cursor: pointer;
            display: flex;
            &:focus,&:hover{
              opacity: 0.7;
            }
          .primaryuploadspan{
            z-index: 2;
            padding: 5px;
            // padding: 5px 15px 5px 15px;
            background: #EF8834;
            border-radius: 56px;
            width: 150px;
            text-align: center;
            
            @media screen and (max-width: 426px) {
              font-size: 8px;
              width: 100px;
            }
        
            @media screen and (max-width: 376px) {
              font-size: 7px;
            //   margin: 3px 0 0 0;
            }
            
            @media screen and (max-width: 321px) {
              font-size: 6px;
            //   margin: 3px 0 0 0;
            }
            
            .uploadlogo{
              width: 20px;
              height: 20px;
              @media screen and (max-width: 426px) {
                width: 10px;
                height: 10px;
              }
            }
        
          }
        .secondaryuploadspan{
          z-index: 1;
          background: rgba(239, 136, 52, 0.15);
          border-radius: 70px;
          color: #949494;
          margin: 0 0 0 -50px;
          padding: 5px 60px;
          width: 100%;
      
          @media screen and (max-width: 426px) {
            padding: 5px 60px 5px auto;
            font-size: 10px;
          }
      
          @media screen and (max-width: 376px) {
            font-size: 8px;
          }
      
          @media screen and (max-width: 321px) {
            font-size: 7px;
          }
        }
      }
            
      .labelshowfile{
        // border: 1px solid red;
        display: flex;
        align-items: center;
        gap: 5px;
        cursor: pointer;
        &:focus,&:hover{
          opacity: 0.7;
        }
        .filelogo{
          width: 40px;
          height: 40px;
          @media screen and (max-width: 426px) {
            width: 30px;
            height: 30px;
          }
        }
        .fileText{
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 326px;
      }
      }

        
        }
        
    .imagesBox{
        width: 100%;
    
        .imgUploadeContainer{
            margin: 20px;

            // align-items: center;
            .imageDescription {
              font-size: 12px;
            }
        }
    }

    .exBox{
        // border: 1px solid red;
        padding-top: 30px;
        .texthead{
            font-weight: bold;
        }
        .inputfiled {
            width: 100%;
            height: 100px;
            background-color: transparent;
            border: 1px solid #828282;
            border-radius: 5px;
            box-sizing: border-box;
            font-family: 'Kanit';
          }
    }
}

//button
.btnFooterWrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 50px;
}

.btnCancle{
    font-family: 'Kanit';
    font-size: 1rem;
    width: 240px;
    height: 40px;
    background-color: #BDBDBD;
    border: none;
    border-radius: 5px;
    color:#ffffff;
    cursor: pointer;
    font-weight: bold
}

.btnNext{
    @extend .btnCancle;
    background-color: #F2C94C;
    color:black;
}

.btnConfirm{
    @extend .btnCancle;
    background: #F2994A;
    // color:black;  
}