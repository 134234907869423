@import '../../variable.scss';

.modalConfirm {
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);

    .card {
        display: flex;
        flex-direction: column;
        background: white;
        border-radius: 10px;
        width: 384px;
        height: 192px;
        
        .textHead{
            font-size: 20px;
            color: black;
            text-align: center;
            font-weight: 700;
            
        }

        .textDes {
            font-size: 16px;
            color: gray;
            text-align: center;
            font-weight: 500;
        }

        .confirmicon{
            margin: 20px auto 5px auto;
            
        }
        .timetext{
            font-weight: 300;
            font-size: 14px;
            text-align: center;
        }
        .rowBtn {
            margin: 25px 0 0 0;
            height: 45px;
        }
    }
}