@import "../../../../variable";

.rightSideContent{
    background-color: $lightgraycolor;
    padding: 20px 20px 40px 20px;
    display: flex;
    flex-direction: column;
    flex-basis: 45%;
    text-align: center;
    gap: 20px;

    .forgotPasswordTopic{
        font-size: 18.72px;
    }
}