@import '../../variable';

.navigationPage {
  background-color: #eee;
  border-radius: 16px;
  padding: 0.5em;
}

.flexEnd {
  justify-content: flex-end;
  gap: 10px;
}

.detailProject {
  @include flexAxisGap(row, 0);

  @media screen and (max-width: 426px) {
    flex-direction: column;
  }
}

.headerWrapper {
  margin: 20px 0 0 0;

  .headCard {
    background-color: $themecolor2;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;

    h3 {
      font-family: $defaultfont;
      font-weight: 500;
    }

    padding: 10px 40px;

    @media screen and (max-width: 426px) {
      padding: 13px 15px;
    }
  }
  .bodyCard {
    background-color: #eee;
    padding: 13px 40px 20px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    @media screen and (max-width: 426px) {
      padding: 13px 15px 60px;
    }
  }
}

.cardHeader {
  display: flex;
  padding: 1em;
  border-radius: 16px;
  background-color: #eee;
  .box {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 8px;
    grid-row-gap: 10px;
    margin-top: 0.5em;
  }
  .wrapperBox {
    display: flex;
    gap: 8px;
  }
}

// For Card
.card {
  margin: 20px 0 0 0;
  @include flexAxisGap(column, 10px);

  // For body of the card
  .bodyCardRadius {
    position: relative;
    background-color: #eee;
    padding: 13px 40px 20px;
    border-radius: 16px;
    padding: 20px 40px;

    @media screen and (max-width: 426px) {
      padding: 20px 15px;
    }

    @include flexAxisGap(column, 5px);

    // Every first child of the text row wil be title
    & .rowText:first-child {
      // color: green;
      font-weight: 500;
      font-size: 30px;
    }
    // Change to second child because the first may be the number!!
    & .rowText:nth-child(2) {
      // color: green;
      font-weight: 500;
      font-size: 30px;
    }

    // Image row, and also Inside of the Upload Image Row
    .rowImage {
      background-color: $themecolor1;
      padding: 10px;

      @include flexXYAdjust(center, center);
      flex-wrap: wrap;
      gap: 10px;

      .rowElement {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 7px;
      }
    }

    // Upload Text Row
    .rowUploadText {
      display: flex;
      flex-direction: row;
      gap: 10px;

      .textValue {
        background-color: $themecolor1;
        padding: 0px 10px;
      }
    }

    // Upload Image Row
    .rowUploadImage {
      background-color: $themecolor1;
      padding: 7px 10px;
      flex-wrap: wrap;
      // gap: 5px;

      @include flexXYAdjust(center, center);
      @include flexAxisGap(column, 0px);

      .titleImage {
        @include flexXYAdjust(flex-start, flex-start);
        width: 100%;
      }
    }

    // Upload Antenna Row
    // .rowUploadAntenna {
    //   background-color: $themecolor1;
    //   padding: 7px 10px;
    //   display: flex;
    //   flex-direction: column;
    //   justify-content: stretch;
    // }

    .rowApprove {
      @extend .rowUploadImage;
      position: relative;

      &.redTab::before {
        content: '';
        position: absolute;
        top: 0px;
        left: 0px;
        background-color: red;
        height: 100%;
        width: 35px;
      }

      .groupApprove {
        @include flexAxisGap(row, 50px);

        @media screen and (max-width: 426px) {
          flex-direction: column;
        }
      }
      .approvalRemarkTextField {
        margin-top: 5px;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid black;
        height: 1.5rem;
        font-size: 1rem;
        width: clamp(100px, 80%, 100%);
        &:focus {
          outline: none;
          background-color: $themecolor2a50;
        }
      }
    }
  }
}

.excelButton {
  padding: 0;
  border: none;
  background: none;
  cursor: pointer;
}

.lineIndexLabel {
  position: absolute;
  left: -40px;
  top: 10px;
  width: 40px;
  height: 40px;
  background-color: $themecolor2;
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;

  font-size: 25px;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;

  &.titleIndexLabel {
    font-size: 17.5px;
  }
}

.imageBorder {
  background-color: #c5c5c5;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  height: 267px; // 4:3 aspect ratio

  &[aria-checked='true'] {
    display: none;
  }

  .imageInside {
    width: 200px;
    height: 267px;
    object-fit: contain;
  }
}

.subCardText {
  border: 0;
  border-bottom: 1px solid #b6b6b6;
  background-color: white;
  font-weight: 400;
  font-size: 16px;
  width: 100%;
  padding: 0.3em;

  &.headerTextField {
    font-size: 32px;
    font-weight: 700;
  }

  &.h2TextField {
    font-size: 24px;
    font-weight: 700;
  }
}

.subCard {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 1em;
  background-color: white;
  flex-direction: column;

  .flexImg {
    display: flex;
    justify-content: center;
    gap: 10px;
  }

  .title {
    font-weight: bold;
  }

  .rowElement {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 8px;
    align-items: center;

    .wrapperTextFile {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 5px;

      .text {
        max-width: 200px;
        font-size: 14px;
        text-align: left;
        overflow: hidden;
      }
    }
  }

  .uploadElement {
    font-family: KanitRegular;
  }

  .rowUploadText {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .textValue {
      background-color: $themecolor1;
      padding: 0px 10px;
    }
  }
}
