.antennaPole {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  gap: 5px;

  .button {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.1rem;
    cursor: pointer;
    font-family: Kanit;
    font-size: 0.9rem;
    img {
      width: 1.2rem;
      height: 1.2rem;
    }

    &.red {
      color: red;
    }
    &.blue {
      color: var(--state-info, #2F80ED);
    }
    &.bigImg {
      img {
        width: 2rem;
        height: 2rem;
      }
    }
  }

  .poleFooter {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 3rem;
    margin: 0.25rem 0;
    gap: 0.25rem;

    .addPoleButton {
      cursor: pointer;
      display: flex;
      gap: 0.25rem;
      justify-content: center;
      align-items: center;
      height: 2.5rem;
      padding: 0 0.75em;
      border: none;
      border-radius: 0.625rem;
      background: var(--primary-main, #FF8206);

      font-size: 1rem;
      color: white;
      font-family: Kanit;

    }
  }
}
.antennaPlane {
  display: flex;
  flex-direction: column;
  justify-content: stretch;

  .tabWrapper {
    display: flex;
    // justify-content: flex-start;
    gap: 10px;
    margin: 0.6rem 0;
    .tab {
      height: 2.7rem;
      padding: 0 1.3em;
      border-radius: 10px;
      border: 1px solid var(--primary-main, #ff8206);
      background: var(--black-white-day-background, #fff);
      color: var(--primary-main, #ff8206);
      text-align: center;
      font-family: Kanit;
      font-size: 1rem;
      cursor: pointer;

      &.active {
        background: var(--primary-main, #ff8206);
        border: none;
        color: white;
      }
    }
  }

  .planeCard {
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid var(--primary-main, #ff8206);
    background: #fff;
    padding: 1rem;

    .planeContent {
      display: flex;
      gap: 1.25rem;
      position: relative;
      // Make pseudo vertical Line
      // https://stackoverflow.com/questions/36029610/how-to-add-vertical-line-between-two-divs
      &:first-child::after {
        content: '';
        background-color: #ececec;
        position: absolute;
        width: 1px;
        height: 100%;
        top: 0px;
        right: 50%;
        display: block;
      }
    }

    .planeFooter {
      display: flex;
      gap: 0.25rem;
      justify-content: end;
    }
  }
}

.colorGroupGrid {
  display: grid;
  grid-template-columns: 8.75rem 1fr 1fr 1fr;
  padding: 0 0.2rem;

  .cell {
    text-align: center;
    padding: 0.25rem 0;
  }
}

.wrapperInput {
  display: grid;
  grid-template-columns: 100px auto;
  gap: 8px;
  align-items: center;
}

.colorGroupBox {
  position: relative;
  width: 22px;
  height: 22px;
  &.circle{
    border-radius: 11px;
  }
  &.grid::before{
    content: '';
    width: 100%;
    position: absolute;
    border-bottom: solid 2px white;
    transform: rotate(-45deg);
    left: 0;
    top: 50%;
    z-index: 1;
  }
  &.grid::after{
    content: '';
    width: 100%;
    position: absolute;
    border-bottom: solid 2px white;
    transform: rotate(45deg);
    left: 0;
    top: 50%;
    z-index: 1;
  }
}