@import '../../variable.scss';

.modalBox {
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    
    .card {
        position: relative;
        display: flex;
        flex-direction: column;
        // justify-content: center;
        padding: 0px 40px;
       padding-top: 30px;
        background: $bg;
        border-radius: 10px;
        width: 450px;
        height: 250px;
        filter: drop-shadow(0px 3px 5px rgba(176, 190, 197, 0.32)) drop-shadow(0px 8px 24px rgba(176, 190, 197, 0.32));
        .textTopic{
            margin: 0;
            font-weight:bold;
            font-size: 1.7rem;
            text-align: center;
        }
        
        .textWrapper{
            display: flex;
            flex-direction: column;
            gap: 20px;
            justify-content: space-around;
        }
        .textContent{
            @extend .textTopic;
            font-size: 1rem;
            font-weight: normal;
        }
       
            .bottom{
                position: absolute;
                bottom: 0px;
                left: 0;
                background-color: $light;
                height: 52px;
                width: 100%;
                box-shadow: 0px 3px 5px rgba(176, 190, 197, 0.32), 0px 8px 24px rgba(176, 190, 197, 0.32);
                border-radius: 0px 0px 5px 5px;
            }
            .buttonWrapper{
                padding:5px 15px;
                display: flex;
                justify-content: space-between;
            }
            .btnTwin{
                display: flex;
                gap: 5px;
            }
            //Button
            .btnBox{
                height: 42px;
                min-width: 70px;
            }
            
    }
 

  }