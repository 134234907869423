.tooltipContainer {
    height: fit-content;
    width: fit-content;
}

.tooltipPopup {
    padding: 4px 8px;
    font-size: 14px;
    border-radius: 4px;
    background-color: rgba(0,0,0, 0.6);
    color: white;
}