/* colors */
$blue: #293b49;
$pink: #fdabaf;
$pink-light: #ffe0e6;
$green: #04cba0;
$green-dark: #01ac88;
$white: white;
$orange: #f2994a;
$textgray: #4f4f4f;
$yellow: #f2c94c;
// html,
// body {
//   height: 100%;
//   margin: 0;
// }

// html {
//   font-size: 62.5%;
// }

body {
  font-family: 'Kanit';
}

.projectSelectionWrapper {
  display: flex;
  // align-items: center;
  margin-top: 10px;

  .projectSelectionButton {
    border: none;
    background-color: transparent;
    font-size: 18px;
    font-weight: 400;
    font-family: "Kanit";
    color: #555555;
    margin-right: 50px;

    cursor: pointer;
    // width: 150px;

    &:hover {
      color: black;
    }

    &.projectSelectionButtonSelected {
      color: black;
      font-weight: 700;

    }
    // @media screen and (max-width: 426px) {
    //   font-size: 20px;
    //   padding: 0;
    //   }
  }
}

.projectListWrapper {
  display: flex;
  flex-direction: column;

  margin-top: 10px;
  padding: 20px 40px;
  background-color: white;

  // border: 3px solid $themecolor2;
  border-radius: 30px;

  .projectListHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .addProjectButton {
      // background: $themecolor2;
      // font-family: $defaultfont;

      font-size: 17px;
      border: none;
      border-radius: 25px;
      padding: 15px 5px;
      box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
    }
  }

  .projectCardWrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    // border-radius: 20px;

    .projectCard {
      display: flex;
      flex-direction: column;
      gap: 8px;
      border-radius: 20px;
      // background-color: $themecolor2a50;
      padding: 15px 30px;

      .projectCardHeader {
        display: flex;
        justify-content: space-between;

        h2 {
          font-size: 25px;
          font-weight: 500;
        }
        svg {
          width: 20px;
          height: 30px;
          cursor: pointer;
        }

        .subTitle {
          color: #555555;
          font-weight: 400;
          font-size: 15px;
        }
      }

      .workTypeCard {
        display: flex;
        background-color: white;
        border-radius: 10px;
        padding: 7px 15px;

        .leftWrapper {
          // flex-basis: 45%;
          display: flex;
          flex: 4 1 0;
          // padding: 0px;
          flex-direction: column;
          gap: 5px;

          .workTypeSection {
            // border: 2px solid red;
            display: flex;
            flex-direction: column;
            h3 {
              font-size: 15px;
              font-weight: 400;
            }

            .spacer {
              flex: 1 1 0;
            }

            p {
              flex: 8 1 0;
              // position: relative;
              font-size: 16px;
              // left: 30px;
            }
            .torFileButton {
              flex: 6 1 0;

              display: flex;
              align-items: center;
              gap: 20px;
              // background-color: $lightgraycolor;
              border: none;
              border-radius: 20px;
              padding: 5px 0px 5px 10px;
            }
          }
        }
        hr {
          margin: 0px;
        }
        .rightWrapper {
          // flex-basis: 45%;
          flex: 3 1 0;
          display: flex;
          gap: 10px;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .buttonWrapper {
            cursor: pointer;
            // background-color: $lightgraycolor;
            width: 100px;
            height: 100px;
            border: none;
            border-radius: 50%;
            box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
            svg {
              width: 50px;
            }
          }

          p {
            cursor: pointer;
            text-decoration: underline;
          }

          .workerimgcontainer {
            display: flex;
            background-color: white;
            align-items: center;
            justify-content: center;
            padding: 35px;
            border-radius: 100%;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
          }
        }
      }
    }
  }
}
.center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $blue;
  gap: 20px;

  .searchbar{
    display: flex;
    justify-content: flex-end;

    .searchbox{
      margin-right: 20px;
     

    }
  }

  .tab {
    width: 100%;
    background-color: $orange;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .buttontab {
      display: flex;
      gap: 50px;
      .bt {
        border: none;
        background-color: transparent;
        font-family: 'Kanit';
        font-size: 20px;
        cursor: pointer;
        color: $textgray;

        &:hover {
          color: black;
        }
      }
    }
  }

  .filterbox {
    width: auto;
    height: auto;
    background: #fffaf7;
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    .layouttext {
      width: 100%;
      .flexfiled {
        display: flex;
        .boxtext {
          display: flex;
          flex-direction: column;
          gap: 5px;
          margin: 20px;
          width: auto;

          .inputfiled {
            width: auto;
            height: 37px;
            background-color: transparent;
            border: 1px solid #828282;
            border-radius: 5px;
            box-sizing: border-box;
            font-family: 'Kanit';
          }
        }
      }
      .bottomfiled {
        display: flex;
        flex-direction: column;
        margin: 0px 20px;
        .flexarea {
          display: flex;
          gap: 20px;
          .boxdate {
            font-size: 12px;
            width: auto;
            .inputfiled {
              width: auto;
              height: 37px;
              background-color: transparent;
              border: 1px solid #828282;
              border-radius: 5px;
              box-sizing: border-box;
              font-family: 'Kanit';
            }
          }
        }
      }
    }

    .leftside {
      width: 100%;
      .radioform {
        display: flex;
        margin: 20px 20px;
        gap: 20px;

        .radio {
          display: flex;
          gap: 10px;
          font-size: 14px;
        }
      }
      .rangePrice {
        margin: 20px;
        .areaRang {
          display: flex;
          justify-content: center;
          .Labelrang {
            width: 110px;
            height: 25px;
            background-color: $orange;
            border-radius: 5px;
            text-align: center;
          }
        }
      }

      .btnform {
        text-align: right;
        margin: 10px;
        .searchBt {
          width: 128px;
          height: 45px;
          border: none;
          background-color: $orange;
          border-radius: 5px;
          color: white;
          font-weight: 600;
          font-family: 'Kanit';
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }

  .cardArea {
    // border: 1px solid red;
    // width: auto;
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin: 20px;
    // border: 1px solid green;
    .cardNews {
      // border: 1px solid red;
      display: flex;
      flex-direction: column;
      position: relative;
      width: 900px;
      height: auto;
      background: #f2f2f2;
      border-radius: 20px;
      .header {
        margin: 20px;
        .headtext {
          font-size: 30px;
          font-weight: 700;

          .redtext {
            color: #fc4f4f;
          }
        }

        .detail {
          display: flex;
          justify-content: space-between;
          .file {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 3px;
            // border: 1px solid red;
            .filebox{
              display: flex;
              gap: 5px;
            }
            .filetext {
              font-size: 14px;
            }
            .logoFile {
              width: 15px;
              height: 15px;
            }
          }

          .companytext {
            font-size: 20px;
            font-weight: 400;
          }
          .subtext {
            .titletext {
              font-weight: bold;
              padding-right: 10px;
            }
          }
        }
      }

      .line {
        border-top: 2px solid $white;
      }

      .twosides {
        margin: 20px;
        display: flex;
        .descript {
          display: flex;
          flex-direction: column;
          gap: 20px;
          width: 100%;
        
          .textdes {
            color: $textgray;
          }

          .workbox{
            // border: 1px solid red;
            display: flex;
            flex-direction: column;
            gap: 5px;

            .textwork{
              display: flex;
              justify-content: space-between;
              
          
              .worktext{
                padding-left: 5px;
              }
            }
            .textsub{
              display: flex;
            }
          }
        }
        .imageside {
          width: 100%;
          text-align: center;
          // border: 1px solid red;

          .imageshow {
            width: 300px;
            height: 221px;
            background-color: #828282;

          }

          .textdate {
            text-align: right;
            font-size: 14px;
            // padding-top: 50px;
          }
        }
      }
      .bottomtext{
        display: flex;
        justify-content: space-between;
        margin: 0 20px 10px 20px;
        // padding-left: 10px;
        // padding-right: 10px;
        .tRegion{
          display: flex;
          gap: 10px;
          .tRegiontext{
          }
          .headtext{
            font-weight: bold;
          }
        }      
      }
      .bottom {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $orange;
        border-radius: 0px 0px 20px 20px;
        height: 60px;
        margin-top: auto;
        .btnform {
          .applyBt {
            // border: 1px solid red;
            border: none;
            width: 350px;
            height: 40px;
            background: $yellow;
            box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 5px;
            font-family: 'Kanit';
            font-size: 18px;
            font-weight: bold;
            cursor: pointer;
          }
        }
      }

      .jobboxArea{
        display: flex;
        flex-direction: column;
        gap: 20px;
        margin: 20px;

        .jobbox{
          background-color: $white;
          border-radius: 20px;
          height: auto;

          .texthead{
            padding: 20px;
            font-size: 20px;
            font-weight: bold;
          }

          .boxtext{
            margin: 20px;
            display: flex;
            gap: 20px;
          }
        }
      }
    }

    .emptyContainer {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      height: 16rem;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      .emptyHeading {
        display: flex;
        column-gap: 10px;
        font-size: 36px;

        .emptyButton {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .emptyLineText {
        font-size: 30px;
      }
    }
  }

 
}

@media only screen and (max-width: 768px) {
  .center {
    .filterbox {
      // background-color: #04cba0;
      margin: 20px;
      font-size: 14px;
    }
    .cardArea {
      .cardNews {
        // border: 1px solid red;
        // height: 100vh;
        width: 650px;
        height: auto;
        font-size: 14px;
      }
    }
  }
}
