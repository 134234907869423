@import '../../variable';

.container {
  display: flex;
  position: relative;
  width: 100%;

  .card {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 1em;
    background-color: #eeeeee;
    border-radius: 16px;
    width: 100%;
    .text {
      font-weight: bold;
    }

    .box {
      display: flex;
      flex-direction: column;
      gap: 10px;
    }

    .wrapperInput {
      display: flex;
      align-items: center;
      width: 100%;
      gap: 8px;
      padding: 0.2em;
      &[aria-checked='true'] {
        background-color: #d7d7d7;
      }
      .icon {
        cursor: pointer;
      }
      .wrapperData {
        display: flex;
        gap: 10px;
        width: 100%;
      }
      .inputLineBottom {
        font-family: 'Kanit';
        border: 0;
        border-bottom: 1px solid #b6b6b6;
        background-color: white;
        font-weight: 400;
        font-size: 15px;
        padding: 0.5em;
        flex-basis: 80%;
        width: 100%;
        ::placeholder {
          font-family: 'Kanit';
        }
        &:focus {
          background-color: #ffdab4;
        }

        &.headerTextField {
          font-size: 32px;
        }
      }
      .inputSelect {
        @extend .inputLineBottom;
        border-radius: 4px;
        border: 1px solid #b6b6b6;
      }
    }
  }
}
