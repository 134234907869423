@import "../../../variable";


.projectSummaryCard{
  // border: 3px solid $themecolor2;
  border-radius: 20px;
  background-color: $lightergraycolor;
  width: 100%;
  margin: 25px 0px;
  padding: 15px 25px;

  display: flex;
  flex-direction: column;
  gap: 10px;
  
  // padding:30px 50px;
  position: relative;

  .letterLine{
    display: flex;
    gap: 20px;
  }
  .summaryWorkPrice{
    text-align: right;
  }
  .summaryWorkQty{
    text-align: right;
  }
}

.textTopic{
  font-size: 1.5rem;
 
}
.thirdpageworkType{
  border-radius: 30px;
  padding: 7px 25px;
  background-color:#EDEDED ;
  display: flex;
  gap: 20px;
}

.filelogo{
  width: 20px;
}
.cardJobType{
  padding: 45px 40px;
  background-color: #E0E0E0;
  border-radius: 15px;
  .topicWrapper{
    display: flex;
    justify-content: space-between;
  }
  //TEXT
  .textTopic{
    font-weight: bold;
    font-size: 1.5rem;
    .workTypeQty{
      text-align: right;
    }
  }
  .cardJobContent{
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin: 10px 0;
  }
  .title{
    font-size: 1.5rem;
  }
  .subTitle{
    font-size: 1rem;
    padding: 0 0 0 10px;
  }
  //Image Group
  .imageWrapper{
    display: flex;
    gap:10px;
  }
  .imageColumnWrapper{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .imgBox{
    width: 205;
    height: 115;
    border: 0.25px solid #000000;
    padding: 0 10px;
    border-radius: 5px;
    background-color: #F7F7F7;
  }
  .imageBoxMini{
    @extend .imgBox;
    padding: 0 ;

    width: 50px;
    height: 40px;
  }
  .image{
    width: 100%;
    height: 100%;
  }
}