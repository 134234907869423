/* colors */
$blue: #293b49;
$pink: #fdabaf;
$pink-light: #ffe0e6;
$green: #04cba0;
$green-dark: #01ac88;
$white: white;
$orange: #f2994a;
$textgray: #4f4f4f;
$yellow: #f2c94c;
// html,
// body {
//   height: 100%;
//   margin: 0;
// }

// html {
//   font-size: 62.5%;
// }

body {
  font-family: 'Kanit';
}

.projectSelectionWrapper {
  display: flex;
  // align-items: center;
  margin-top: 10px;

  .projectSelectionButton {
    border: none;
    background-color: transparent;
    font-size: 18px;
    font-weight: 400;
    font-family: 'Kanit';
    color: #555555;
    margin-right: 50px;

    cursor: pointer;
    // width: 150px;

    &:hover {
      color: black;
    }

    &.projectSelectionButtonSelected {
      color: black;
      font-weight: 700;
    }
    // @media screen and (max-width: 426px) {
    //   font-size: 20px;
    //   padding: 0;
    //   }
  }
}

.center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: $blue;
  gap: 20px;

  .topbar {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-bottom: 20px;

    .Icon {
      width: 42px;
      height: 42px;
    }
    .textback {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .filterbox {
    width: 900px;
    height: auto;
    background: #fffaf7;
    border: 1px solid #dbdbdb;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    .oneside {
      width: 100%;
      .flexfiled {
        display: flex;

        .boxtext {
          display: flex;
          flex-direction: column;
          gap: 5px;
          margin: 20px;
          width: auto;

          .inputfiled {
            width: auto;
            height: 37px;
            background-color: transparent;
            border: 1px solid #828282;
            border-radius: 5px;
            box-sizing: border-box;
            font-family: 'Kanit';
          }
        }
      }
    }

    .leftside {
      width: 100%;
    
      .radioform {
        display: flex;
        flex-direction: column;
        margin: 20px 20px;
        gap: 10px;

        .radio {
          display: flex;
          gap: 10px;
          font-size: 14px;
        }
      }
      .rangePrice {
        margin: 20px;
        .areaRang {
          display: flex;
          justify-content: center;
          .Labelrang {
            width: 110px;
            height: 25px;
            background-color: $orange;
            border-radius: 5px;
            text-align: center;
          }
        }
      }

      .btnform {
        text-align: right;
        margin: 10px;
        .searchBt {
          width: 128px;
          height: 45px;
          border: none;
          background-color: $orange;
          border-radius: 5px;
          color: white;
          font-weight: 600;
          font-family: 'Kanit';
          font-size: 16px;
          cursor: pointer;
        }
      }
    }
  }

  .cardArea {
    margin-top: 20px;
    // border: 1px solid green;
    // width: 900px;
    .cardFilter {
      background-color: #ffcda4;
      display: flex;
      flex-direction: column;
      gap:5px;
      border-radius: 15px;
      padding-bottom: 20px;
      .statusBar{
        display: flex;
        padding: 15px;
        align-items: flex-end;
        flex-direction: column;
        font-size: 14px;
      }

      .headtext {
        display: flex;
        flex-direction: column;
        padding: 25px ; 
        // border: 1px solid red;
        // margin: 20px;
        .texthead {
          font-size: 36px;
          font-weight: 700;
          color: #333333;
        }
        .projecttext{
          font-size: 25px;

        }
      }

      .workcard {
        display: flex;
        flex-direction: column;
        gap: 10px;
        background-color: #f2f2f2;
        // border: 1px solid red;
        margin: 0px 20px;
        border-radius: 15px;
        padding-bottom: 20px;

        .headtextJob {
          padding: 20px;
          .textwork {
            font-weight: 700;
            font-size: 28px;
          }
          .texttotal {
            font-weight: 400;
            font-size: 20px;

            .textsub{
              padding-left: 10px;
            }
            .textsubs{
              padding-right: 10px;
            }
          }
        }

        .companycard {
          
          background-color: $white;
          border-radius: 15px;
          margin: 0px 20px;
          .areaCard {
            display: flex;
            padding: 10px;
            .leftside {
              display: flex;
              flex-direction: column;
              gap: 10px;
              // border: 1px solid green;
              width: 100%;
              margin: 10px;
              .showfile{
                cursor: pointer;
                // padding-left: 25px;
                // text-align: right;     
            .textfile{
                // &:focus,&:hover{
                //     opacity: 0.7;
                //   }
                padding-left: 10px;
                display: flex;
                gap: 5px;
            }
            .fileText{
                cursor: pointer;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                width: 326px;
            }
            }
              .safetypill{
                display: flex;
                gap: 20px;
                .pill{
                  // width: aupx;
                  background-color: #FFCDA4;
                  border-radius: 15px;
                  font-weight: bold;
                  text-align: center;
                  padding: 5px;
                }
              }
              .textcompany {
                font-weight: 700;
                font-size: 20px;
                .textTeamname{
                  font-weight: 400;
                }
              }
              .title {
                font-weight: 700;
              }

              .image {
                border: 1px solid #828282;
                // background-color: #828282;
                border-radius: 5px;
                width: fit-content;
                // height: 200px;
              }

              .subdetail {
                display: flex;
                flex-direction: column;
                gap: 5px;
              }
            }
            .rigthside {
              width: 100%;
              margin: 10px;
              .head {
                .countTeam {
                  display: flex;
                  gap: 10px;
                  justify-content: flex-end;
                  align-items: center;
                  .title {
                    font-weight: 700;
                  }
                }
              }
              .table {
                margin: 5rem 1rem;
                // height: 300px;
                // border: 1px solid red;
              }

              .buttonbottom {
                display: flex;
                gap: 10px;
                justify-content: flex-end;

                .btCancle{
                  font-family: 'Kanit';
                  color: $white;
                  width: 145px;
                  height: 42px;
                  background-color: #BDBDBD;
                  border: none;
                  border-radius: 5px;
                  cursor: pointer;
                }

                .btApprove{
                  font-family: 'Kanit';
                  width: 145px;
                  color: $white;
                  height: 42px;
                  background-color: $orange;
                  border: none;
                  border-radius: 5px;
                  cursor: pointer;



                }
              }
            }
          }
        }
      }
    }
  }

  .modal {
    font-family: 'Kanit';
   
    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      background: white;
      border-radius: 20px;
      // width: 800px;
      width: auto;
      height: auto;

      .Area {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 20px;
        // justify-content: space-between;
        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .textHead {
            font-size: 27px;
            font-weight: bold;
            text-align: center;
          }
        }

        .textCompany {
          font-size: 20px ;
          font-weight: 400;
        }

        .TableShow{
          display: flex;
          justify-content: center;
        }

        .cardJobs{
          background-color: $white;
          border-radius: 15px;
          height: auto;
          display: flex;
          flex-direction: column;
          gap: 10px;
          padding: 10px 20px;

          .twoside{
            display: flex;
            height: 250px;
            gap: 20px;
            .table{
              // border: 1px solid red;
              width: 100%;
            }
            .imageURL{
              // border: 1px solid red;
              width: 100%;
            }
          }

          .areatext{
            // margin: 10px;
            .title{
              display: flex;
              justify-content: space-between;
              .jobtext{
                font-size: 18px;
                font-weight: bold;
                
              }
            }
            
          }
        }

        .footerTableContainer{
          margin: 10px;
        }
      }

      .buttonform{
        margin: 20px;
      }

    
    }
  }

}

@media only screen and (max-width: 768px) {
  .center {
    .filterbox {
      // background-color: #04cba0;
      width: 769px;
      font-size: 14px;
    }
    .cardArea {
      .cardNews {
        // border: 1px solid red;
        // height: 100vh;
        width: 650px;
        height: auto;
        font-size: 14px;
      }
    }
  }
}
