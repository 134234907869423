// @import '../../abstracts/variables';

//Profile Upload
.container{
    position: relative;
    display: flex;
    justify-content: end;
    align-items: flex-end;
    cursor: pointer;
    // background-color: hotpink;
    



}
.imageContainer img{
    width: 120px;
    border-radius:50% ;
    height: 120px;
    @media screen and (max-width:'1024px') {
    height: 90px;
        
    }
}

.dotCamera {
    border:none;
    position: absolute;
    display: flex;
    justify-content:center;
    align-items: center;
    height: 35px;
    width: 35px;
    background-color: #ffffff;
    border-radius: 50%;
    box-shadow: 0px 3px 5px rgba(176, 190, 197, 0.32), 0px 8px 24px rgba(176, 190, 197, 0.32);
  }