@import "/src/variable";

.reportStatusContainer{
    display: flex;
    flex-direction: column;
    gap: 10px;
    // padding-bottom: 10px;
    .head{
        display: flex;
        justify-content: space-between;
        align-items: center;

        .titleHead{
            font-size: 22px;
            font-weight: bold;
        }

        .rightHead{
            display: flex;
            gap: 10px;

            .refreshButton{
                color: #ffffff;
                // background-color: #f8ea25;
                background-color: #006E7F;
                border: none;
                cursor: pointer;
                border-radius: 3px;
                font-weight: 700;


                display: flex;
                align-items: center;

            }
        }
        
    }
    .exportBtnContainer{
        display: flex;
        justify-content: flex-end;
    }
    .date {
        .titleHead{
            font-size: 18px;
            font-weight: bold;
        }
    }

    // .tableContainer {
    //     border: 1px solid red;
    // }

    .search{
        display: flex;
        gap: 10px;

        .boxdate{
            width: 200px;
        }
        .inputfiled{
            height: 30px;
            border-radius: 5px;
            font-family: 'Kanit';
        }
    }
    
}

.loader {
    display:inline-block;
    margin-right: 0.5em;

    border: 3px solid #f3f3f3;
    border-top: 3px solid #3498db;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;    
}
@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}