@import "../../variable";


.projectSelectionWrapper{
    background-color: $themecolor2;
    display: flex;
    align-items: center;
    margin-top: 10px;
    height: 50px;
    // padding: 0px 50px;
    // justify-content: flex-start;
  
    // @media screen and (max-width: 426px) {
    //   padding: 0px 10px;
    //   }
  
    .projectSelectionButton{
  
        font-family: $defaultfont;
        border: none;
    //   border-radius: 100px;
        background-color: transparent;
    // background-color: green;
    //   padding: 23px 50px;
        font-size: 20px;
        font-weight: 400;
    //   font-weight: 900;
        color: #555555;
      // display: flex;
      // flex-wrap: nowrap;
        cursor: pointer;
        width: 150px;
  
        &:hover{
            color: black;
        }

        &.projectSelectionButtonSelected {
            color: black;
        }
        // @media screen and (max-width: 426px) {
        //   font-size: 20px;
        //   padding: 0;
        //   }
    }
  }




.projectListWrapper{
    // border: 2px solid red;

    display: flex;
    flex-direction: column;
    
    margin-top: 10px;
    padding: 20px 40px;
    background-color: white;
    
    // border: 3px solid $themecolor2;
    border-radius: 30px;

    

    .projectListHeader{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .addProjectButton{
            background: $themecolor2;
            font-family: $defaultfont;
            
            font-size: 17px;
            border: none;
            border-radius: 25px;
            padding: 15px 5px;
            box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
        }

    }


    .projectCardWrapper{
                // border: 2px solid red;

        display: flex;
        flex-direction: column;
        gap: 20px;
        
        margin-top: 20px;
        // border-radius: 20px;
        
        .projectCard{
            display: flex;
            flex-direction: column;
            gap: 8px;
            border-radius: 20px;
            background-color: $themecolor2a50;
            padding: 15px 30px;

            .projectCardHeader{
                display: flex;
                justify-content: space-between;

                h2 {
                    font-size: 25px;
                    font-weight: 500;
                }
                svg {
                    width: 20px;
                    height: 30px;
                    cursor: pointer;
                }

                .subTitle{
                    color: #555555;
                    font-weight: 400;
                    font-size: 15px;
                }
            }

            
            .workTypeCard{
                // border: 2px solid red;
                display: flex;
                background-color: white;
                border-radius: 10px;
                padding: 45px 15px;
                gap: 20px;
              
                .imageJob{
                    width: 250px;
                    height: 200px;
                }

                .leftWrapper{
                    // flex-basis: 45%;
                    display: flex;
                    flex: 4 1 0;
                    // padding: 0px;
                    flex-direction: column;
                    gap: 5px;

                    .workTypeSection{
                        // border: 2px solid red;
                        display: flex;
                        flex-direction: column;
                        h3 {
                            font-size: 15px;
                            font-weight: 400;
                        }

                        .spacer{
                            flex: 1 1 0;
                        }

                        p {
                            flex: 8 1 0;
                            // position: relative;
                            font-size: 16px;
                            // left: 30px;
                        }
                        .torFileButton{
                            flex: 6 1 0;

                            display: flex;
                            align-items: center;
                            gap: 20px;
                            background-color: $lightgraycolor;
                            border: none;
                            border-radius: 20px;
                            padding: 5px 0px 5px 10px;
                        }
                        .topicCard{
                            font-size: 0.9rem;
                            font-weight: bold;
                        }
                        .title{
                                font-size:0.8rem;
                        }
                        .list{
                            font-size:0.8rem;

                            margin:0;
                            padding: 0 18px;
                        }
                    }

                   
                }
                hr {
                    margin: 0px;
                }
                .rightWrapper{
                    // flex-basis: 45%;
                    flex: 2 1 0;
                    display: flex;
                    gap: 10px;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    .buttonWrapper {
                        cursor: pointer;
                        background-color: $lightgraycolor;
                        width: 100px;
                        height: 100px;
                        border: none;
                        border-radius: 50%;
                        box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
                        svg {
                            width: 50px;
                        }
                    }

                    p {
                        cursor: pointer;
                        text-decoration: underline;
                    }

                    .workerimgcontainer{
                        display: flex;
                        background-color: white;
                        align-items: center;
                        justify-content: center;
                        padding: 35px;
                        border-radius: 100%;
                        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
                    }
                }
            }

            .AddTechArea{
                display: flex;
                align-items: center;
                gap: 10px;
                padding-bottom: 10px;
                .avatar{
                    // border: 1px solid red;
                    background-color: #EF8834;
                    // font-weight: bold;
                    font-family: 'Kanit';
                    border: 2px solid #ffffff;
                }
                .IconAdd{
                    width: 56px;
                    height: 56px;
                }
                .tooltip {
                    // position: relative;
                    display: inline-block;
                    border-bottom: 1px dotted black;
                  }
                  
                  .tooltip .tooltiptext {
                    visibility: hidden;
                    width: 120px;
                    background-color: black;
                    color: #fff;
                    text-align: center;
                    border-radius: 6px;
                    padding: 5px 0;
                  
                    /* Position the tooltip */
                    position: absolute;
                    z-index: 5;
                  }
                  
                  .tooltip:hover .tooltiptext {
                    visibility: visible;
                  }
            }
        }
    }
    
}

.myjob{
    border-radius: 50px;
    background-color: white;
    display: flex;
    flex-direction: column;
    
}

.createProjectButton{
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: white;
    width: 270px;
    height: 270px;
    border: 0px;
    // margin: 0 0 20px 0;
    padding: 10px 10px;
    &:hover{
        background-color: #C4C4C4;
    }

    h2 {
        font-weight: bold;
        font-size: 25px;
        font-family: $defaultfont;
    }
    p {
        font-size: 16px;
        font-family: $defaultfont;
        margin-top: 0px;
    }
}

.announcementmodalcontainer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    padding: 30px 80px;
    border-radius: 20px;
    font-family: "Kanit";

    .header{
        display: flex;
        align-items: center;
        justify-content: center;
        // padding: 12px 42px 14px 74px;
        padding: 0 0 10px 0;
        font-weight: bolder;
    }

    .body{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        font-weight: bold;

        .btnline{
            display: flex;
            justify-content: center;
            gap: 20px;
        }
    }
}

.genPatModalContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    padding: 30px 80px;
    border-radius: 20px;
    font-family: "Kanit";
}
.genPatModalWrapper{
    // display: flex;
    height: 150px;
    // width: 400px;
}

.jobSelectionContainer{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    padding: 30px 20px;
    border-radius: 20px;
    font-family: "Kanit";
}
.jobSelectionModalWrapper{
    height: 500px;
    width: 600px;

    .btnLine{
        display: flex;
        justify-content: center;
        gap: 20px;
    }
}

.btDetail{
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: transparent;
    border: none;
    cursor: pointer;
    border-radius: 100%;
    width: 20px;
    height: 20px;
    padding: 15px;
    &:hover{
        background-color: $lightergraycolor;
    }
}

.menulist{
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 10px;

    .menuIcon{
        width: 20px;
        height: 20px;
    }
}

.modalBox {
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border: none;
    // z-index: 2;
    // border: 1px solid red;
    
    .cardModal {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 10px;
        // justify-content: center;
        padding: 0px 20px;
        padding-top: 20px;
        background-color: #ffffff;
        border-radius: 10px;
        width: 429px;
        height: 500px;
        border: none;
        overflow-y: scroll;

        // height: 399px;
      
          
        .head{
            display: flex;
            justify-content: space-between;
            .textHead {
                font-size: 22px;
            }
        }
        .TechBox{
            display: flex;
            flex-direction: column;
            gap: 15px;
            padding-bottom: 30px;


            .dataBox{
                display: flex;
                gap: 5px;
                align-items: center;
                justify-content: space-between;
                


                .grop{
                    display: flex;
                    gap: 10px;
                    align-items: center;


                    .TextGroup{

                        .textType{
                            color: #A4A4A4;
                            font-size: 14px;
                        }
                        .textJobType{
                            // border: 1px solid red;
                            padding-left: 5px;
                        }
                    }
                }

            

            }
        }
        .avatar{
            background-color: #EF8834;
            font-family: 'Kanit';
        }

        .inputText{
            background-color: #EFEFEF;
            height: 32px;
            border-radius: 5px;
            border: none;
            width: 100%;
            font-family: 'Kanit';
        }
     
       
           
    }
    .cardModal::-webkit-scrollbar {
        // display: none;
        width: 5px;
        // background: red; 
        border-radius: 5px;
      }
      .cardModal::-webkit-scrollbar-thumb {
        background: #FFCDA4; 
        border-radius: 50px;
        padding: 5px;
      }
      
    

  }

  .menuTech{
    //   background-color: #555555;
    font-family: 'Kanit';
    display: flex;
    justify-content: space-between;

    .GroupAvatar{
        display: flex;
        align-items: center;
        gap: 10px;
    }
   
    // font-family: 'Kanit';
    // display: flex;
    // gap: 20px;
    // align-items: center;
    // border: 1px solid red
}
