//Verify
.container{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // color: $blue;
    padding-top: 20px;
    gap: 20px;


    .contentWrapper{
      width: 900px;
      background-color: #F2994A;
      padding: 30px;
    
      .card{
          background-color: #ffffff;
          padding:  50px 20px;
          border-radius:5px;
          display: flex;
          gap: 40px;
          position: relative;
      }
    }
    .detailWrapper{
      display: flex;
      gap:50px;
    }
    .addressWrapper{
      width: 300px;
      text-align: justify;
      text-justify: inter-word;
    }
    .contractWrapper{
      display: flex;
      flex-direction: column;
      gap: 10px;
    
    }
    .inputUserName{
      outline: none;
      height: 30px;
      border: 1px solid #BBBBBB;
      border-radius: 5px;
      padding-left: 5px;
    }
    
    //* TEXT
    .textIconWrapper{
     display: flex;
     gap:10px;
    }
    .textname{
      font-size: 20px;
      display: flex;
      gap: 10px;
    }
    .textAddress{
      padding-left: 20px;
      color:#626262;
    }
    .textTitle{
      font-size: 1rem;
    }
    .textTitleLeft{
      padding-left: 15px;
      font-size: 1rem;
    }
    .textSubTitle{
      color:#626262;
    }
    .textStatusReject{
      color: #FD0000;
      font-size: 1rem;
    }
    .textStatusAccept{
      color: #9BF00F;
    }
    //* Button
    .btnChangePassword{
      font-family: 'Kanit';
      font-size: 1rem;
      background: none;
      border: transparent;
      text-decoration: underline;
      color: #F2994A;
      cursor: pointer;
    }
    .btnEditProfile{
      cursor: pointer;
      border: transparent;
      padding: 10px 15px;
      color:#ffffff;
      background-color: #F2994A;
      border-radius: 5px;
      position: absolute;
      top:15px;
      right: 20px;
    }
    
  
  }
  
  //! Verify-1
  .verifyFristContainer{
      width: 900px;
      display: flex;
      flex-direction: column;
      gap: 30px;
      // margin: 20px;
      padding: 30px;
      .flexbox{
        display: flex; 
        gap: 30px;
  
        .inputleft{
   
          width: 100%;    
  
          .inputArea{
            display: flex;
            flex-direction: column;
            gap: 10px;
            .flexinput{
              display: flex;
              gap: 10px;
            }
  
            .addressBox{
              display: flex;
              flex-direction: column;
              gap: 10px;
            }
          }
         
        }
        .inputrigth{
          padding-top: 10px;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 15px;
          
        .safetyArea{
          display: flex;
          gap: 15px;
          align-items: center;

        
          .textAccept{
            font-size: 12px;
          font-weight: bold;
            color: #27AE60 ;
          }
          .textPending{
            font-size: 12px;
            font-weight: bold;

            color: #F2994A;
          }
        }
  


          .check{
            display: flex;
            gap: 10px;
          }
          .upload{
            display: flex;
            flex-direction: column;
            // gap: 5px;
          }
  
       
  
          .radioGroup{
            display: flex;
            flex-direction: column;
            gap: 10px;
            .texthead{
              font-weight: bold;
            }
            .radio{
              display: flex;
              gap: 20px;
  
  
            }
  
        
          }
  
          
  
        }
      }
  
      .emerBox{
        display: flex;
        flex-direction: column;
        gap: 10px;
        padding-top: 20px;
  
        .flexinput{
          display: flex;
          gap: 10px;
  
          .GroupInput{
            width: 100%;
            display: flex;
            flex-direction: column;
  
            .flexInput{
              gap: 20px;
              display: flex;
            }
          }
        }
      }
      .inputWrapper{
          display: flex;
          flex-direction: column;
          gap: 20px;
      }
      .radioWrapper{
          display: flex;
          gap: 50px;
      }
      .textTitle{
  
          font-size: 1.5rem;
      }
      .selectWrapper{
        display: grid;
        grid-template-columns: 1fr 1fr;
        justify-items: stretch;
        align-items: stretch;
        column-gap: 20px;
        row-gap: 20px;
      }
      .rowWrapper{
        display: flex;
        align-items: baseline;
        gap:20px;
      }
      .inputRowBox{
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: end;
        column-gap: 20px;
        // display: flex;
        // align-items: flex-end;
        // justify-content: space-between;
        // gap:20px;
        // width: 600px;
      }
  }
  
  //! Verify-3
  .verifythirdContainer{
      width: 900px;
      display: flex;
      flex-direction: column;
      gap: 30px;
  }
  .inputfile{
      width: 0.1px;
        height: 0.1px;
        opacity: 0;
        overflow: hidden;
        position: absolute;
        z-index: -1;
    }
      
    .labelfile{
      font-size: 13px;
      font-weight: bold;
      cursor: pointer;
      display: flex;
      &:focus,&:hover{
        opacity: 0.7;
      }
    .primaryuploadspan{
      display: flex;
      z-index: 2;
      padding: 5px 15px 5px 15px;
      background: #EF8834;
      border-radius: 56px;
      width: 150px;
      // text-align: center;
    
      
      @media screen and (max-width: 426px) {
        font-size: 8px;
        width: 100px;
      }
  
      @media screen and (max-width: 376px) {
        font-size: 7px;
      //   margin: 3px 0 0 0;
      }
      
      @media screen and (max-width: 321px) {
        font-size: 6px;
      //   margin: 3px 0 0 0;
      }
      
      .uploadlogo{
        width: 20px;
        height: 20px;
        @media screen and (max-width: 426px) {
          width: 10px;
          height: 10px;
        }
      }
  
    }
  .secondaryuploadspan{
    z-index: 1;
    background: rgba(239, 136, 52, 0.15);
    border-radius: 70px;
    color: #949494;
    margin: 0 0 0 -50px;
    padding: 5px 60px;
    width: 100%;
  
    @media screen and (max-width: 426px) {
      padding: 5px 60px 5px auto;
      font-size: 10px;
    }
  
    @media screen and (max-width: 376px) {
      font-size: 8px;
    }
  
    @media screen and (max-width: 321px) {
      font-size: 7px;
    }
  }
  }
  .labelshowfile{
      cursor: pointer;
      &:focus,&:hover{
      opacity: 0.7;
      }
      .filelogo{
      width: 40px;
      height: 40px;
      @media screen and (max-width: 426px) {
          width: 30px;
          height: 30px;
      }
      }
  }
  .imageUploadWrapper{
    display: flex;
    justify-content: center;
  }
  .imgCardId{
    position: relative;
    background-color:#FBC391 ;
    border-radius: 5px;
    width: 700px;
    height: 400px;
    display: flex;
    justify-content: center;
    
    padding: 10px 150px;
  
    .image{
      width: 100%;
    }
    .upLoadImage{
      cursor: pointer;
      position: absolute;
      top:170px;
      height: 60px;
      width: 60px;
      background-color: #FBC391;
      opacity: 0.6;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .addIcon{
      color: #5F5F5F;
    }
  }
  
  
  
  
  
  
  
  //! Input
  .inputText{
      // width: 540px;
      width: 100%;
      height: 37px;
      outline: none;
      border-radius: 5px;
      border: 1px solid #BBBBBB;
      padding: 5px;
  
  
  }
  .inputTextSmall{
    @extend .inputText;
    width: 200px;
  }
  .inputSelectBox{
      width: 200px;
  }
  .inputSelect{
    outline: none;
    height: 30px;
    border-radius: 5px;
    border: 1px solid #BBBBBB;
    width: 100%;
   
  }
  
  //! button 
  .btnFooterWrapper{
      font-family: 'Kanit';
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
  }
  
  .btnCancle{
      font-size: 1rem;
      width: 240px;
      font-family: 'Kanit';
      height: 40px;
      background-color: #BDBDBD;
      border: none;
      border-radius: 5px;
      color:#ffffff;
      cursor: pointer;
  }
  
  .btnNext{
      @extend .btnCancle;
      background-color: #F2C94C;
      color:black;
  }
  
  .btnConfirm{
      @extend .btnCancle;
      background: #F2994A;
      color:black;  
  }
  //* Text
  .textTitle{
      font-size: 1.25rem;
  }
  
  
  //ModalVerify
  .modalVerifyAlert {
    position: absolute;
    float: left;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  
    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      // padding: 30px 40px;
      background: #F9F9FB;
      border-radius: 10px;
      width: 400px;
      height: 222px;
  
      .textGroup{
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 30px;
        gap: 20px;
  
        .texthead{
          font-weight: bold;
          font-size: 24px;
  
          .text{
            color: #626262;
          }
        }
      }
      
      .bottom {
        position: absolute;
        bottom: 0px;
        margin-top: 10rem;
        background-color: #F9F9FB;
        height: 56px;
        width: 400px;
        border-radius: 0px 0px 5px 5px;
  
        .btnForm{
          display: flex;
          justify-content: space-between;
          padding:5px;
  
          .btCancle{
            width: 87px;
            font-family: 'Kanit';
            height: 40px;
            background-color: #FF6161;
            border: none;
            border-radius: 5px;
            color:#ffffff;
            cursor: pointer;
        }
  
        .btSkip{
          @extend .btCancle;
          background-color: #F2994A;
        }
        .btVerify{
          @extend .btCancle;
          background-color: #3A77DA;
        }
        }
      }
      .buttonWrapper {
        padding: 10px;
      }
    }
  
    
  }

  

  .modal {
    font-family: 'Kanit';
   
    .card {
      position: relative;
      display: flex;
      flex-direction: column;
      background: #FFFFFF;;
      border-radius: 10px;
      width: 900px;
       .inputfiled {
        width: 100%;
        height: 37px;
        background-color: transparent;
        border: 1px solid #828282;
        border-radius: 5px;
        box-sizing: border-box;
        font-family: 'Kanit';
      }
  
      .Area {
        display: flex;
        flex-direction: column;
        gap: 5px;
        margin: 20px;
        .head {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-top: 10px;
          .textHead {
            font-size: 27px;
            font-weight: bold;
            text-align: center;
          }
        }
  
        .groupInput{
          display: flex;
          flex-direction: column;
          gap:30px;
          padding: 20px;
  
          .upload{
            display: flex;
            flex-direction: column;
            gap: 10px;
          }
  
          .IssueInput {
            font-size: 15px ;
            font-weight: 400;
  
            .inputIssue{
              @extend .inputfiled;
              height: 100px;
            }
          }
        }
      }
  
      .buttonform{
        margin: 20px;
      }
  
      .btnFooterWrapper{
        padding-top: 5rem;
        font-family: 'Kanit';
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }
    
    .btnCancle{
        font-size: 1rem;
        width: 240px;
        font-family: 'Kanit';
        height: 40px;
        background-color: #BDBDBD;
        border: none;
        border-radius: 5px;
        color:#ffffff;
        cursor: pointer;
    }
    
    .btnNext{
        @extend .btnCancle;
        background-color: #F2C94C;
        color:black;
    }
    
    .btnConfirm{
        @extend .btnCancle;
        background: #F2994A;
        color:black;  
    }
    //* Text
    .textTitle{
        font-size: 1.25rem;
    }
    }
  }