@import '../../variable';

.subCard {
  display: flex;
  justify-content: center;
  gap: 16px;
  padding: 1em;
  background-color: white;
  flex-direction: column;

  .inputInTable {
    font-family: 'Kanit';
    border: 0;
    border-bottom: 1px solid #b6b6b6;
    background-color: #fafafa;
    font-weight: 400;
    font-size: 14px;
    width: 100%;

    ::placeholder {
      font-family: 'Kanit';
    }

    &.headerTextField {
      font-size: 32px;
    }
  }
  .selectInputTable {
    font-family: KanitRegular;
    border: 0;
    font-size: 14px;
    border-radius: 4px;
    width: 100%;
    box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  }

  .wrap {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .wrapperRow {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .tableMain {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;

    tr {
      padding: 0.5em;
      background-color: #ffe0c1;
      border-radius: 4px;
    }

    tr:first-child td:first-child {
      border-top-left-radius: 4px;
    }
    tr:first-child td:last-child {
      border-top-right-radius: 4px;
    }

    tr:last-child td:first-child {
      border-bottom-left-radius: 4px;
    }
    tr:last-child td:last-child {
      border-bottom-right-radius: 4px;
    }

    tr:first-child td {
      border-top-style: none;
    }
    tr td:first-child {
      border-left-style: none;
    }

    td {
      text-align: center;
      padding: 0.1em 0.2em;

      &:first-child {
        text-align: left;
      }
    }
  }

  .tableHeader {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0;
    td {
      text-align: center;
      padding: 0.1em 0.2em;
    }
    tr {
      border: 1px solid green;
    }
  }
}
