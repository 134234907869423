@import "../../../../variable";

.rightSideContent{
    background-color: $lightgraycolor;
    padding: 20px 20px 40px 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-basis: 45%;
    gap: 20px;

    .fillNewPasswordItems{
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 10px;
        
        .fillNewPasswordTopic{
            font-size: 18.72px;
        }
        .textFieldLine{
            
        }
        .validationTextGroup{
            text-align: left;
        } 
    }
}