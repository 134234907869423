@import '../../variable';

.panelfirstline {
  padding: 0 0px 0 0px;
  position: relative;

  .panelform {
    @include flexAxisGap(column, 10px);

    width: 100%;
    background-color: #eeeeee;
    padding: 1.5em;
    border-radius: 16px;

    .patname {
      width: 100%;
      font-size: 32px;
      border: none;
      padding: 0 0.3em;
      background-color: white;
      border-bottom: 1px solid #b6b6b6;
      font-family: 'Kanit';
    }

    .selectForm {
      @extend .patname;
      font-size: 16px;
      padding: 0.3em;
    }

    .patdescription {
      @extend .patname;
      font-size: 22px;
    }

    .text {
      font-weight: bold;
    }

    .wrap {
      display: flex;
      gap: 16px;
    }
  }
}
