@import "../../../../variable";

.rightSideContent{
    background-color: $lightgraycolor;
    padding: 20px;
    display: flex;
    flex-direction: column;
    flex-basis: 45%;
    justify-content: space-between;

    .emailverifyItems{
        display: flex;
        flex-direction: column;
        align-items: center;

        .emailverifyTopic{
            font-size: 18.72px;
        }
        .emailpic{
            width: 90px;
        }
        .emailShow{
            color: $themecolor2;
            text-decoration: underline;
        }
    }
}